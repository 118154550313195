import React, { useContext, useState } from "react";
import { Form, Button, Alert } from "react-bootstrap";
import logo from "../assets/lims.circle.png";
import "./Login.css";
import { AuthContext } from "../Services/AuthContext";
import { AxiosContext } from "../Services/AxiosContext";
import { Navigate } from "react-router-dom";
import QRScanner from "../Components /QRScanner";
import axios from "axios";

const Login = ({ onLogin }) => {
  const [credentials, setCredentials] = useState({
    email: "",
    password: "",
  });
  const [loginError, setLoginError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const authContext = useContext(AuthContext);
  const { publicAxios } = useContext(AxiosContext);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setCredentials({ ...credentials, [name]: value });
  };

  if (authContext.authState.authenticated) {
    return <Navigate to="/dashboard" />;
  }

  const handleSubmit = async (event) => {
    event.preventDefault();

    // Determine the URL based on the window location origin
    let apiUrl;
    if (window.location.origin.includes("localhost")) {
      apiUrl = "https://www.caessia.com/api/token/";
    } else if (window.location.origin.includes("lims.caessia.com")) {
      apiUrl = "https://login.caessia.com/api/token/";
    } else {
      apiUrl = "https://login.seednexus.com/api/token/";
    }

    try {
      // Send a POST request to the determined API endpoint with the user's credentials
      const response = await publicAxios.post(apiUrl, credentials);
      //console.log(response);

      if (response.data.access && response.data.refresh) {
        const { access, refresh } = response.data;

        // Set the authentication state
        authContext.setAuthState({
          accessToken: access,
          refreshToken: refresh,
          authenticated: true,
        });

        // Store the tokens in localStorage
        await localStorage.setItem(
          "authTokens",
          JSON.stringify({
            accessToken: access,
            refreshToken: refresh,
          })
        );
        await localStorage.setItem("tokenExpired", "false");

        // Redirect or handle login success
        // onLogin(credentials);
      } else {
        setLoginError(true);
        setErrorMessage("Invalid credentials. Please try again.");
      }
    } catch (error) {
      //console.log(error);

      // Provide user-friendly error messages
      let errorMessage =
        "An unexpected error occurred. Please try again later.";

      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        if (error.response.status === 400) {
          errorMessage =
            "Invalid username or password. Please check your credentials and try again.";
        } else if (error.response.status === 401) {
          errorMessage = "Unauthorized access. Please check your credentials.";
        } else if (error.response.status === 403) {
          errorMessage = "You do not have permission to access this resource.";
        } else if (error.response.status === 404) {
          errorMessage = "The requested resource was not found.";
        } else if (error.response.status === 500) {
          errorMessage =
            "There is a problem with the server. Please try again later.";
        } else {
          errorMessage = `Error: ${error.response.status}. Please try again later.`;
        }
      } else if (error.request) {
        // The request was made but no response was received
        errorMessage =
          "No response from the server. Please check your internet connection.";
      } else {
        // Something happened in setting up the request that triggered an Error
        errorMessage =
          "There was an issue with your request. Please try again.";
      }

      setLoginError(true);
      setErrorMessage(errorMessage);
    }
  };

  return (
    <div className="center-container">
      {loginError && <Alert variant="danger">{errorMessage}</Alert>}
      <div className="login-container">
        <div className="login-card">
          <img src={logo} alt="Company Logo" className="logo-img" />
          <h2 className="login-heading">Sign In</h2>
          <Form onSubmit={handleSubmit} className="login-form">
            <Form.Group controlId="email">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="text"
                name="email"
                value={credentials.email}
                onChange={handleInputChange}
                required
              />
            </Form.Group>
            <Form.Group controlId="password">
              <Form.Label>Password</Form.Label>
              <Form.Control
                type="password"
                name="password"
                value={credentials.password}
                onChange={handleInputChange}
                required
              />
            </Form.Group>
            <Button variant="primary" type="submit" className="login-button">
              Login
            </Button>
            {/* {loginError && <Alert variant="danger">{errorMessage}</Alert>} */}
          </Form>
        </div>
        {/* <QRScanner/> */}
      </div>
    </div>
  );
};

export default Login;
