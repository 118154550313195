import React from "react";
import { Routes, Route } from "react-router-dom";
import ProtectedRoute from "./ProtectedRoute"; // Import your custom ProtectedRoute component
import Dashboard from "../MainPages/Dashboard";
import Data from "../MainPages/Data";
import About from "../MainPages/About";
import ProjectsList from "../MainPages/ProjectsList";
import Settings from "../MainPages/SettingsFolder/Settings";
import Stations from "../MainPages/SettingsFolder/Stations";
import Blocks from "../MainPages/SettingsFolder/Blocks";
import Ports from "../MainPages/SettingsFolder/Ports";
import Images from "../MainPages/Images";
import ProjectDetails from "../MainPages/DetailFIles/ProjectDetails";
import Formulas from "../MainPages/SettingsFolder/Formulas";
import Protocols from "../MainPages/SettingsFolder/Protocols";
import Stats from "../MainPages/Stats";
import Inventory from "../MainPages/InventoryFolder/Inventory";
import InventoryArchitecture from "../MainPages/InventoryFolder/inventoryArchitecture";
import Users from "../MainPages/SettingsFolder/Users";
import Login from "../Login&Logout/Login";
import Docs from "../MainPages/SettingsFolder/Docs";

const AppRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Dashboard />} />
      <Route path="/dashboard" element={<Dashboard />} />
      <Route path="/reports" element={<About />} />
      <Route path="/data" element={<Data />} />
      <Route path="/projects" element={<ProjectsList />} />
      <Route path="/settings" element={<Settings />} />
      <Route path="/stats" element={<Stats />} />
      <Route path="/inventory" element={<Inventory />} />
      {/* <Route path="/login" element={<Login />} /> */}



      <Route path="/images" element={<Images />} />

      {/* Sub Routes */}
      <Route path="/settings/stations" element={<Stations />} />
      <Route path="/settings/users" element={<Users />} />
      <Route path="/settings/blocks" element={<Blocks />} />
      <Route path="/settings/ports/:param" element={<Ports />} />
      <Route path="/project/:param" element={<ProjectDetails />} />
      <Route path="/settings/formulas" element={<Formulas />} />
      <Route path="/settings/protocols" element={<Protocols />} />
      <Route path="/settings/inventory" element={<InventoryArchitecture />} />
      <Route path="/settings/docs" element={<Docs />} />


    </Routes>
  );
};

export default AppRoutes;
