import React, { useState, useRef, useEffect } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
import ToolkitProvider, {
  CSVExport,
  ColumnToggle,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min";
import "bootstrap/dist/css/bootstrap.min.css";
import "../Styles/StatsTable.css";
import { CSVLink } from "react-csv";
import { CustomToggleList, MyExportCSV, exportCSV } from "./CustomFunctions";
import { Accordion, Col, Row, Button } from "react-bootstrap"; // Added Button
import paginationFactory from "react-bootstrap-table2-paginator";
import GraphStats from "./GraphStats";
import SaveInventory from "./SaveInventory";

const InventoryDataSource = ({ data }) => {
  const { ExportCSVButton } = CSVExport;
  const { ToggleList } = ColumnToggle;
  const [graphData, setGraphData] = useState(null);
  const [clickedDatasetLabel, setClickedDatasetLabel] = useState(null);
  const [combinedTableRows, setCombinedTableRows] = useState([]); // State to store combined tableRows
  const [selectedRows, setSelectedRows] = useState([]);
  const [showSaveInventory, setShowSaveInventory] = useState(false);

  const handleOpenSaveInventory = () => setShowSaveInventory(true);
  const handleCloseSaveInventory = () => {
    setShowSaveInventory(false)
    // setSelectedRows([]);
  };

  const handleDatasetClick = (label) => {
    setClickedDatasetLabel(label);
  };

  const tableRef = useRef(null);

  useEffect(() => {
    setSelectedRows([]);
    setGraphData(null);
  }, [data]);

  const renderTableAccordionItem = (tableData, index, clickedDatasetLabel) => {
    if (!Array.isArray(tableData)) {
      return null; // Return early if tableData is not an array
    }

    let tableRows = [];
    let uniquePorts = new Set();
    let avgStatsKeys = new Set();

    tableData.forEach((item) => {
      if (!Array.isArray(item.data)) {
        return; // Skip iteration if item.data is not an array
      }

      item.data.forEach((task) => {
        const row = {
          task: task.task,
          block: item.block,
          task_name: task.task_name,
          station: task.stations,
          data_dict: task.data_dict,
        };

        let hasPort = false;

        if (Array.isArray(task.records)) {
          task.records.forEach((record) => {
            if (record.port) {
              uniquePorts.add(record.port);
              row[record.port] = record.value_num;
              hasPort = true;
            } else if (
              record.avg_stats &&
              typeof record.avg_stats === "object"
            ) {
              Object.entries(record.avg_stats).forEach(([key, value]) => {
                if (record.avg_stats.hasOwnProperty(key)) {
                  row[key] = value;
                  avgStatsKeys.add(key);
                }
              });
            }
          });
        }

        if (
          !hasPort &&
          Array.isArray(task.records) &&
          task.records.length > 0 &&
          task.records[0].avg_stats &&
          typeof task.records[0].avg_stats === "object"
        ) {
          const avgStats = task.records[0].avg_stats;
          Object.keys(avgStats).forEach((key) => {
            if (avgStats.hasOwnProperty(key)) {
              row[key] = avgStats[key];
              avgStatsKeys.add(key);
            }
          });
        }

        tableRows.push(row);
      });
    });

    let columns = [];

    if (tableRows.some((row) => typeof row.data_dict === "object")) {
      const dataDictKeys = Object.keys(tableRows[0].data_dict); // Get keys of the first row's data_dict

      if (
        dataDictKeys.includes("CSI") &&
        dataDictKeys.includes("Farm") &&
        dataDictKeys.includes("Trial") &&
        dataDictKeys.includes("Year")
      ) {
        // Use specific data_dict keys as columns
        columns = [
          {
            dataField: "data_dict.CSI",
            text: "CSI",
            sort: true,
            filter: textFilter(),
          },
          {
            dataField: "data_dict.Farm",
            text: "Farm",
            sort: true,
            filter: textFilter(),
          },
          {
            dataField: 'Lot_Number_or_Line_ID',
            text: "Lot Number / Line ID",
            sort: true,
            filter: textFilter(),
            formatter: (cell, row) => row.data_dict.Lot_Number ? row.data_dict.Lot_Number : row.data_dict.Line_ID,
          }
          
          ,
          {
            dataField: "data_dict.Trial",
            text: "Trial",
            sort: true,
            filter: textFilter(),
          },
          {
            dataField: "data_dict.Year",
            text: "Year",
            sort: true,
            filter: textFilter(),
          },
          ...Array.from(uniquePorts).map((port) => ({
            dataField: port,
            text: port,
          })),
          ...Array.from(avgStatsKeys).map((key) => ({
            dataField: key,
            text: key,
            sort: true,
            filter: textFilter(),
          })),
        ];
      } else {
        // Fallback to default columns
        columns = [
          {
            dataField: "data_dict.string_identifier",
            text: "Item",
            sort: true,
            filter: textFilter(),
          },
          {
            dataField: "task_name",
            text: "Task Name",
            sort: true,
            filter: textFilter(),
          },
          { dataField: "block", text: "Block" },
          ...Array.from(uniquePorts).map((port) => ({
            dataField: port,
            text: port,
            sort: true,
          })),
          ...Array.from(avgStatsKeys).map((key) => ({
            dataField: key,
            text: key,
            sort: true,
            filter: textFilter(),
          })),
        ];
      }
    } else {
      // Fallback to default columns
      columns = [
        {
          dataField: "task_name",
          text: "Task Name",
          sort: true,
          filter: textFilter(),
        },
        { dataField: "block", text: "Block" },
        ...Array.from(uniquePorts).map((port) => ({
          dataField: port,
          text: port,
        })),
        ...Array.from(avgStatsKeys).map((key) => ({
          dataField: key,
          text: key,
          sort: true,
          filter: textFilter(),
        })),
      ];
    }
    // //console.log(tableRows);

    const handleSelect = (row, isSelect) => {
      if (isSelect) {
        setSelectedRows([...selectedRows, row]);
      } else {
        setSelectedRows(
          selectedRows.filter((selectedRow) => selectedRow.task !== row.task)
        );
      }
    };

    const selectRow = {
      mode: "checkbox",
      clickToSelect: true,
      style: { backgroundColor: "#c8e6c9" },
      onSelect: handleSelect,
      
    };

    return (
      <ToolkitProvider
        keyField="task"
        data={tableRows}
        columns={columns}
        exportCSV
        columnToggle
      >
        {(props) => (
          <div>
            {/* <CustomToggleList {...props.columnToggleProps} /> */}
            <BootstrapTable
              bootstrap4
              {...props.baseProps}
              ref={tableRef}
              filter={filterFactory()}
              defaultSorted={[{ dataField: "task", order: "asc" }]}
              columns={columns}
              hover
              responsive
              selectRow={selectRow}
              pagination={paginationFactory({
                firstPageText: "First",
                prePageText: "Prev",
                nextPageText: "Next",
                lastPageText: "Last",
                nextPageTitle: "Go to next",
                backgroundColor: "#4E73DF",
              })}
            />

            {/* <MyExportCSV
              {...props.csvProps}
              onExportClick={() =>
                handleExportClick("export", tableRows, props.columnToggleProps)
              }
              onGraphClick={() =>
                handleExportClick("graph", tableRows, props.columnToggleProps)
              }
            /> */}
          </div>
        )}
      </ToolkitProvider>
    );
  };

  const handleExportClick = (btntype, tableRows, toggles) => {
    // //console.log(tableRows);

    // Filter out columns with toggle set to false
    const filteredColumns = toggles.columns.filter(
      (column) => toggles.toggles[column.dataField]
    );
    // //console.log(filteredColumns);

    // Extract data for filtered columns
    const filteredData = tableRows.map((row) => {
      const filteredRow = {};
      filteredColumns.forEach((column) => {
        if (column.dataField.startsWith("data_dict.")) {
          const dictKey = column.dataField.replace("data_dict.", "");
          filteredRow[column.text] = row.data_dict
            ? row.data_dict[dictKey]
            : "";
        } else {
          filteredRow[column.text] = row[column.dataField];
        }
      });
      return filteredRow;
    });
    // //console.log(filteredData);
    if (btntype === "graph") {
      setGraphData(filteredData);
    } else if (btntype === "export") {
      exportCSV(filteredData);
    }
  };

  const handleCombineRowsClick = () => {
    // Combine tableRows from all tables
    const combinedRows = data.reduce((accumulator, currentValue) => {
      currentValue.forEach((item) => {
        if (Array.isArray(item.data)) {
          // //console.log(item.data);
          item.data.forEach((task) => {
            const row = {
              task: task.task,
              block: item.block,
              task_name: task.task_name,
              station: task.stations,
              data_dict: task.data_dict,
            };
            accumulator.push(row);
          });
        }
      });
      return accumulator; // Add this return statement
    }, []);

    // //console.log(combinedRows);
    setCombinedTableRows(combinedRows);
  };

  const getSelected = async (data) => {
    // //console.log(selectedRows);
    handleOpenSaveInventory();
  };

  return (
    <div className="stats-table">
      <Row style={{ backgroundColor: "white" }}>
        <Col md={12}>
          {data.map((tableData, index) => (
            <div key={index}>
              {/* Render table header */}
              <div style={{ fontWeight: "bold" }}>
                {tableData && tableData.length > 0 && tableData[0].block
                  ? tableData[0].block
                  : "NO DATA FOUND"}
              </div>
              {/* Render table body */}
              <div
                style={{
                  backgroundColor: "white",
                  // maxHeight: "400px",
                  // overflowY: "auto",
                }}
              >
                {tableData &&
                tableData.length > 0 &&
                !tableData[0].noDataFound ? (
                  renderTableAccordionItem(
                    tableData,
                    index,
                    clickedDatasetLabel
                  )
                ) : (
                  <div>NO DATA FOUND</div>
                )}
              </div>
            </div>
          ))}
        </Col>
      </Row>
      <Button onClick={getSelected}>Create Inventory</Button>
      {selectedRows.length !== 0 && (
        <SaveInventory
          show={showSaveInventory}
          handleClose={handleCloseSaveInventory}
          data={selectedRows}
        />
      )}
    </div>
  );
};

export default InventoryDataSource;
