import JSZip from "jszip";
import { saveAs } from "file-saver";
import async from "async";

export const exportImagesToZip = async (data) => {
  const zip = new JSZip();
  const imageInFolder = zip.folder('image-in');
  const imageOutFolder = zip.folder('image-out');

  // //console.log(data)

  const downloadQueue = async.queue(async (obj) => {
    try {
      const { image_data, task } = obj;
      const { image_in, image_out } = image_data;
      const { data_dict } = obj;
      const csi = data_dict.string_identifier ? data_dict.string_identifier : data_dict.CSI; // Corrected field

      // Fetch and add image_in
      const imageInResponse = await fetch(image_in);
      if (imageInResponse.ok) {
        const imageInBlob = await imageInResponse.blob();
        imageInFolder.file(`${csi}.jpg`, imageInBlob);
      } else {
        console.error(`Failed to fetch image_in for ${csi}`);
      }

      // Fetch and add image_out
      const imageOutResponse = await fetch(image_out);
      if (imageOutResponse.ok) {
        const imageOutBlob = await imageOutResponse.blob();
        imageOutFolder.file(`${csi}.jpg`, imageOutBlob);
      } else {
        console.error(`Failed to fetch image_out for ${csi}`);
      }
    } catch (error) {
      console.error(`Error processing ${obj}:`, error);
    }
  }, 10); // Limit to 10 concurrent downloads

  // Push each object to the download queue
  data.forEach(obj => {
    downloadQueue.push(obj);
  });

  // Wait for all downloads to finish
  await new Promise((resolve) => {
    downloadQueue.drain(() => {
      //console.log("All images processed.");
      resolve();
    });
  });

  const zipBlob = await zip.generateAsync({ type: 'blob' });
  saveAs(zipBlob, 'exported_images.zip');
};


// Usage:
// Call exportImagesToZip(data) with your array of objects
