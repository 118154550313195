import React, { useEffect, useReducer, useState } from "react";
import {
  Modal,
  Form,
  Button,
  Alert,
  Container,
  Spinner,
} from "react-bootstrap";
import Select from "react-select";
import apiCalls from "../Services/AxiosCalls";

const initialState = {
  facility: null,
  rack: null,
  shelf: null,
  box: null,
  seed_amount: null,
  task: null,
  seed_info: "",
  threshold: null,
  unit: null,
  notes: "",
  expiring_date: null,
};

function formReducer(state, action) {
  switch (action.type) {
    case "update":
      return { ...state, [action.field]: action.value };
    case "reset":
      return initialState;
    default:
      return state;
  }
}

const SaveInventory = ({ show, handleClose, data }) => {
  const [formData, dispatch] = useReducer(formReducer, initialState);
  const [storageFacilities, setStorageFacilities] = useState([]);
  const [amountOptions, setAmountOptions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const weightUnits = [
    { label: "micrograms", value: "micrograms" },
    { label: "milligrams", value: "milligrams" },
    { label: "grams", value: "grams" },
    { label: "kilograms", value: "kilograms" },
    { label: "metric tons", value: "metric tons" },
    { label: "grains", value: "grains" },
    { label: "drachms", value: "drachms" },
    { label: "ounces", value: "ounces" },
    { label: "pounds", value: "pounds" },
    { label: "stones", value: "stones" },
    { label: "quarters", value: "quarters" },
    { label: "hundredweights", value: "hundredweights" },
    { label: "short tons", value: "short tons" },
    { label: "long tons", value: "long tons" },
  ];

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await apiCalls.getStorageFacility();
        console.log(response.data);
        setStorageFacilities(response.data);
        const options = Object.keys(data[0]).map((key) => ({
          value: key,
          label: key,
        }));
        setAmountOptions(options);
      } catch (err) {
        setError("Failed to fetch storage facilities.");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [data]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validate fields
    if (
      !formData.facility ||
      !formData.rack ||
      !formData.shelf ||
      !formData.seed_amount
    ) {
      setError("Please fill in all required fields.");
      return;
    }

    setIsSubmitting(true);
    const failedItems = [];

    const saveInventoryPromises = data.map((item) => {
      const inventoryData = {
        location_box: formData.box,
        location_shelf: formData.shelf,
        task: item.task,
        seed_amount: item[formData.seed_amount],
        seed_info: formData.seed_info,
        notes: formData.notes,
        expiring_date: formData.expiring_date,
        threshold: formData.threshold,
        unit: formData.unit,
      };
      return apiCalls.postInventory(inventoryData).catch(() => {
        failedItems.push(inventoryData);
      });
    });

    try {
      await Promise.all(saveInventoryPromises);
      if (failedItems.length > 0) {
        setError(`Failed to save ${failedItems.length} item(s).`);
      } else {
        setSuccess(true);
        dispatch({ type: "reset" });
      }

    } catch (error) {
      setError("Error saving inventory.");
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleChange = (field) => (selectedOption) => {
    dispatch({
      type: "update",
      field,
      value: selectedOption ? selectedOption.value : null,
    });
  };

  // Reset error and success states on modal close
  const handleCloseModal = () => {
    handleClose();
    setError(false);
    setSuccess(false);
  };

  const isSaveDisabled =
    isSubmitting ||
    !formData.facility ||
    !formData.rack ||
    !formData.shelf ||
    !formData.seed_amount ||
    !formData.unit;

  return (
    <Modal show={show} onHide={handleCloseModal} centered>
      <Modal.Header closeButton>
        <Modal.Title>Save Inventory</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {error && (
          <Alert variant="danger" dismissible onClose={() => setError(false)}>
            {error}
          </Alert>
        )}
        {success && (
          <Alert
            variant="success"
            dismissible
            onClose={() => setSuccess(false)}
          >
            Inventory saved successfully
          </Alert>
        )}
        {loading ? (
          <Spinner animation="border" role="status">
            <span className="sr-only">Loading...</span>
          </Spinner>
        ) : (
          <Container>
            <Form onSubmit={handleSubmit}>
              <div className="row">
                {/* Storage Facility Select */}
                <div className="col-md-6 mb-3">
                  <Form.Group>
                    <Form.Label>Storage Facility</Form.Label>
                    <Select
                      value={storageFacilities
                        .map((facility) => ({
                          value: facility.id,
                          label: facility.name,
                        }))
                        .find((option) => option.value === formData.facility)}
                      onChange={handleChange("facility")}
                      options={storageFacilities.map((facility) => ({
                        value: facility.id,
                        label: facility.name,
                      }))}
                      placeholder="Select Facility"
                    />
                    <Form.Text className="text-muted">
                      Choose a facility to store the items.
                    </Form.Text>
                  </Form.Group>
                </div>

                {/* Rack Select */}
                {formData.facility && (
                  <div className="col-md-6 mb-3">
                    <Form.Group>
                      <Form.Label>Rack</Form.Label>
                      <Select
                        value={storageFacilities
                          .find((f) => f.id === formData.facility)
                          ?.racks.map((rack) => ({
                            value: rack.id,
                            label: rack.name,
                          }))
                          .find((option) => option.value === formData.rack)}
                        onChange={handleChange("rack")}
                        options={storageFacilities
                          .find((f) => f.id === formData.facility)
                          ?.racks.map((rack) => ({
                            value: rack.id,
                            label: rack.name,
                          }))}
                        placeholder="Select Rack"
                      />
                    </Form.Group>
                  </div>
                )}

                {/* Shelf Select */}
                {formData.rack && (
                  <div className="col-md-6 mb-3">
                    <Form.Group>
                      <Form.Label>Shelf</Form.Label>
                      <Select
                        value={storageFacilities
                          .find((f) => f.id === formData.facility)
                          ?.racks.find((r) => r.id === formData.rack)
                          ?.shelves.map((shelf) => ({
                            value: shelf.id,
                            label: shelf.name,
                          }))
                          .find((option) => option.value === formData.shelf)}
                        onChange={handleChange("shelf")}
                        options={storageFacilities
                          .find((f) => f.id === formData.facility)
                          ?.racks.find((r) => r.id === formData.rack)
                          ?.shelves.map((shelf) => ({
                            value: shelf.id,
                            label: shelf.name,
                          }))}
                        placeholder="Select Shelf"
                      />
                    </Form.Group>
                  </div>
                )}

                {/* Box Select */}
                {formData.shelf && (
                  <div className="col-md-6 mb-3">
                    <Form.Group>
                      <Form.Label>Box</Form.Label>
                      <Select
                        value={storageFacilities
                          .find((f) => f.id === formData.facility)
                          ?.racks.find((r) => r.id === formData.rack)
                          ?.shelves.find((s) => s.id === formData.shelf)
                          ?.boxes.map((box) => ({
                            value: box.id,
                            label: `Box ${box.number}`,
                          }))
                          .find((option) => option.value === formData.box)}
                        onChange={handleChange("box")}
                        options={storageFacilities
                          .find((f) => f.id === formData.facility)
                          ?.racks.find((r) => r.id === formData.rack)
                          ?.shelves.find((s) => s.id === formData.shelf)
                          ?.boxes.map((box) => ({
                            value: box.id,
                            label: `Box ${box.number}`,
                          }))}
                        placeholder="Select Box"
                      />
                    </Form.Group>
                  </div>
                )}
              </div>

              {/* Seed Amount Select */}
              <Form.Group className="mb-3">
                <Form.Label>Seed Amount</Form.Label>
                <Select
                  value={amountOptions.find(
                    (option) => option.value === formData.seed_amount
                  )}
                  onChange={handleChange("seed_amount")}
                  options={amountOptions}
                  placeholder="Select seed amount"
                />
              </Form.Group>

              {/* Threshold Input */}
              <Form.Group className="mb-3">
                <Form.Label>Threshold</Form.Label>
                <Form.Control
                  type="number"
                  value={formData.threshold || ""}
                  onChange={(e) =>
                    dispatch({
                      type: "update",
                      field: "threshold",
                      value: e.target.value,
                    })
                  }
                  required
                />
              </Form.Group>

              {/* Units Select */}
              <Form.Group className="mb-3">
                <Form.Label>Units</Form.Label>
                <Select
                  value={weightUnits.find(
                    (option) => option.value === formData.unit
                  )}
                  onChange={handleChange("unit")}
                  options={weightUnits}
                  placeholder="Select unit"
                  required
                />
              </Form.Group>

              {/* Notes Input */}
              <Form.Group className="mb-3">
                <Form.Label>Notes</Form.Label>
                <Form.Control
                  as="textarea"
                  value={formData.notes}
                  onChange={(e) =>
                    dispatch({
                      type: "update",
                      field: "notes",
                      value: e.target.value,
                    })
                  }
                />
              </Form.Group>

              {/* Expiring Date Input */}
              <Form.Group className="mb-3">
                <Form.Label>Expiring Date</Form.Label>
                <Form.Control
                  type="date"
                  value={formData.expiring_date || ""}
                  onChange={(e) =>
                    dispatch({
                      type: "update",
                      field: "expiring_date",
                      value: e.target.value,
                    })
                  }
                />
              </Form.Group>

              {/* Seed Info Input */}
              <Form.Group className="mb-3">
                <Form.Label>Seed Info</Form.Label>
                <Form.Control
                  as="textarea"
                  value={formData.seed_info}
                  onChange={(e) =>
                    dispatch({
                      type: "update",
                      field: "seed_info",
                      value: e.target.value,
                    })
                  }
                />
              </Form.Group>

              {/* Save Button */}
              <Button variant="primary" type="submit" disabled={isSaveDisabled}>
                {isSubmitting ? (
                  <Spinner animation="border" role="status" size="sm" />
                ) : (
                  "Save"
                )}
              </Button>
            </Form>
          </Container>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default SaveInventory;
