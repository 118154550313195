import React, { useEffect, useState } from "react";
import { Form, Button, Stack, Alert, Row, Col } from "react-bootstrap";
import apiCalls from "../../Services/AxiosCalls";
import { useFlashMessage } from "../../Components /FlashMessageContext";
import Select from "react-select";
import DataTable from "../../Components /DataTable";
import EditModal from "./EditModal";
import { CSVLink } from "react-csv";
import ExportCSV from "./ExportCSV";


const BoxForm = () => {
  const [formData, setFormData] = useState({
    description: "",
    number: null,
    shelf: null,
  });
  const { showFlashMessage } = useFlashMessage();
  const [options, setOptions] = useState(null);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);
  const [showForm, setShowForm] = useState(true);
  const [boxes, setBoxes] = useState([]); // State variable to toggle between form and DataTable
  const [openEdit, setOpenEdit] = useState(false);
  const [selectedRow, setSelectedRowId] = useState(null);
  const [downloadData, setDownloadData] = useState(null);

  

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleSelectChange = (selectedOption) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      shelf: selectedOption.value,
    }));
  };
  const fetchData = async () => {
    try {
      const shelves = await apiCalls.getShelves();
      const boxData = await apiCalls.getBoxes();
      setBoxes(boxData.data);
      //console.log(shelves.data);
      const shelfData = shelves.data.map((data) => ({
        label: `Shelf ${data.name} of Rack ${data.rack_name}`,
        value: data.id,
      }));
      setOptions(shelfData);
      const items = {
        items: boxData.data.map((data) => ({
          title: `Box ${data.number} of shelf ${data.shelf_number}`,
          data: JSON.stringify({ key: "Box", id: data.id, name: data.number }).replace(/"/g, '""'), // Properly escape quotes
          description: data.description
        }))
      };
      setDownloadData(items);
    } catch (error) {
      console.error("Error fetching shelf data:", error);
    }
  };
  

  useEffect(() => {
    fetchData(); // Call the async function inside useEffect
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    setSuccess(false);

    try {
      // Submit the form data
      const response = await apiCalls.postBox(formData);
      //console.log("Form submitted:", response.data);
      setSuccess(true);
      fetchData();
    } catch (error) {
      console.error("Error submitting form:", error);
      setError(error.message);
    }
  };
  const handleToggle = () => {
    setShowForm((prevShowForm) => !prevShowForm); // Toggle between showing form and DataTable
  };
  const headers = ["number", "shelf_number", "rack_name"];

  const handleRowClick = (rowData) => {
    //console.log(rowData);
    setOpenEdit(true);
    setSelectedRowId(rowData);
  };

  const toggleEditModal = () => {
    setOpenEdit((prevOpenEdit) => !prevOpenEdit);
    fetchData(); // Call the async function inside useEffect
  };

 

  return (
    <>
      <Row className="justify-content-between">
        <Col md={4}>
          <h5>Box</h5>
        </Col>
        <Col className="d-flex justify-content-end">
          <Button
            variant="link"
            onClick={handleToggle}
            className="d-flex justify-content-end"
          >
            {showForm ? "EDIT BOX" : "CREATE BOX"}{" "}
          </Button>
        </Col>
      </Row>
      {showForm ? (
        <>
          {error && <Alert variant="danger">{error}</Alert>}
          {success && <Alert variant="success">Box saved successfully!</Alert>}
          <Form onSubmit={handleSubmit}>
            <Form.Group>
              <Select
                options={options}
                onChange={handleSelectChange}
                placeholder="Select Shelf"
                required
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formNumber">
              <Form.Label>Box Number</Form.Label>
              <Form.Control
                type="number"
                placeholder="Enter box number"
                name="number"
                value={formData.number}
                onChange={handleChange}
                required
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formDescription">
              <Form.Label>Description</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                placeholder="Enter description"
                name="description"
                value={formData.description}
                onChange={handleChange}
              />
            </Form.Group>

            <Stack direction="horizontal" gap={3}>
              <Button variant="primary" type="submit">
                Submit
              </Button>
              {/* <Button variant="outline-danger">Cancel</Button> */}
            </Stack>
          </Form>
        </>
      ) : (
        <>
          {/* <Button
            variant="link"
            onClick={postQRCode}
            className="d-flex justify-content-end"
          >
            Get PDF
          </Button> */}
          <ExportCSV data={downloadData} />

          <DataTable
            headers={headers}
            data={boxes}
            onRowClick={handleRowClick}
          />
        </>
      )}
      {openEdit && (
        <EditModal
          show={openEdit}
          onHide={toggleEditModal}
          resourceType={"Boxes"}
          resourceData={selectedRow}
          title={`Box ${selectedRow.number}`}
        />
      )}
    </>
  );
};

export default BoxForm;
