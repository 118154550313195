import React, { useState, useEffect } from "react";
import Papa from "papaparse";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFileCsv,
  faSave,
  faCheckCircle,
  faExclamationTriangle,
} from "@fortawesome/free-solid-svg-icons";
import apiCalls from "../Services/AxiosCalls";
import { ProgressBar, Button, Alert, Spinner } from "react-bootstrap";

const UploadSeedInventory = ({ data, blockData }) => {
  const [parsedData, setParsedData] = useState([]);
  const [progress, setProgress] = useState(0);
  const [uploadStatus, setUploadStatus] = useState(null);
  const [loading, setLoading] = useState(false);
  const [storageFacilities, setStorageFacilities] = useState([]);
  const [matches, setMatches] = useState([]);
  const [unmatchedRecords, setUnmatchedRecords] = useState([]); // For unmatched data
  const [failedSaves, setFailedSaves] = useState([]); // For failed saves

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await apiCalls.getStorageFacility();
        setStorageFacilities(response.data);
      } catch (err) {
        console.error("Error fetching storage facilities:", err);
      }
    };
    fetchData();
  }, [blockData]);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      Papa.parse(file, {
        header: true,
        complete: (results) => {
          const processedData = results.data.map((row) => processRow(row));
          setParsedData(processedData);

          // Reset status and progress when a new file is uploaded
          setUploadStatus(null);
          setProgress(0);

          // Find matches and unmatched records after processing
          const { matchedRecords, unmatchedRecords } =
            findMatches(processedData);
          setMatches(matchedRecords);
          setUnmatchedRecords(unmatchedRecords); // Store unmatched records

          console.log("Matched Records:", matchedRecords);
          console.log("Unmatched Records:", unmatchedRecords); // Log unmatched records
        },
        error: (error) => {
          console.error("Error parsing CSV:", error);
        },
      });
    }
  };

  const processRow = (row) => {
    const locationParts = row.Location.split("-");
    const facilityName = locationParts[0];
    const rackName = locationParts[1];
    const shelfName = locationParts[2];
    const boxNumber = locationParts[3];

    const facility = storageFacilities.find((fac) => fac.name === facilityName);
    if (!facility) {
      console.warn(`Facility ${facilityName} not found`);
      return null;
    }

    const rack = facility.racks.find((rack) => rack.name === rackName);
    const shelf = rack?.shelves.find((shelf) => shelf.name === shelfName);
    const box = shelf?.boxes.find((box) => box.number === parseInt(boxNumber));

    if (!rack || !shelf || !box) {
      console.warn(
        `Location part missing: rack ${rackName}, shelf ${shelfName}, box ${boxNumber}`
      );
      return null;
    }

    return {
      ...row,
      location_box: box.id,
      location_shelf: shelf.id,
      facility_id: facility.id,
    };
  };

  const findMatches = (parsedData) => {
    const matchedRecords = [];
    const unmatchedRecords = [];

    parsedData.forEach((row) => {
        const match = blockData.find((block) =>
            block.data_dict.CSI === row.CSI &&
            block.data_dict.Plot.toString() === row.Plot.toString() &&
            (
              block.data_dict.Lot_Number?.toString() === row.Lot_Number?.toString() ||
              (!block.data_dict.Lot_Number && block.data_dict.Line_ID?.toString() === row.Lot_Number?.toString())
            )
          );
          
          

      if (match) {
        // If a match is found, add the matched row and block to matchedRecords
        matchedRecords.push({ ...row, match });
      } else {
        // If no match is found, add the row to unmatchedRecords
        unmatchedRecords.push(row);
      }
    });

    return { matchedRecords, unmatchedRecords };
  };

  const createInventoryPostData = (match) => {
    console.log(match);

    const inventoryData = {
      location_box: match.location_box,
      location_shelf: match.location_shelf,
      task: match.match.task, // Assuming match object has 'task'
      seed_amount: parseFloat(match["Inventory"]), // Convert inventory to float
      seed_info: match.match.data_dict.Crop, // Assuming Crop is needed as seed info
      notes: match["Note"] ? match["Note"] : "", // Set to an empty string or modify as needed
      expiring_date: null, // Set to an empty string or modify as needed
      threshold: match["Threshold"] ? match["Threshold"] : 0, // Set to an empty string or modify as needed
      unit: match["unit"],
    };
    return inventoryData;
  };

  const handleSave = async () => {
    setLoading(true);
    setProgress(0);
    const failedItems = [];

    for (let i = 0; i < matches.length; i++) {
      const data = matches[i];
      const inventoryData = createInventoryPostData(data);

      try {
        await apiCalls.postInventory(inventoryData); // Uncomment in actual use
        setProgress(((i + 1) / matches.length) * 100); // Update progress bar
      } catch (error) {
        console.error("Error saving inventory data for record:", data, error);
        failedItems.push(data);
      }
    }

    setFailedSaves(failedItems);
    setUploadStatus(failedItems.length > 0 ? "error" : "success");
    setLoading(false);
  };

  return (
    <div className="upload-seed-inventory-container" style={containerStyle}>
      <h2>
        <FontAwesomeIcon icon={faFileCsv} /> Upload Seed Inventory
      </h2>

      <input
        type="file"
        accept=".csv"
        onChange={handleFileChange}
        className="form-control"
        style={{ marginBottom: "20px" }}
      />

      {uploadStatus === "success" && (
        <Alert variant="success">
          <FontAwesomeIcon icon={faCheckCircle} /> Inventory saved successfully!
        </Alert>
      )}
      {uploadStatus === "error" && (
        <Alert variant="danger">
          <FontAwesomeIcon icon={faExclamationTriangle} /> Error saving some
          inventory data.
          {failedSaves.length > 0 && (
            <ul>
              {failedSaves.map((item, idx) => (
                <li
                  key={idx}
                >{`Failed to save: CSI ${item.CSI}, Lot ${item.Lot_Number}`}</li>
              ))}
            </ul>
          )}
        </Alert>
      )}

      {unmatchedRecords.length > 0 && (
        <Alert variant="warning">
          <FontAwesomeIcon icon={faExclamationTriangle} /> The following items
          could not be matched:
          <ul>
            {unmatchedRecords.map((item, idx) => (
              <li
                key={idx}
              >{`CSI ${item.CSI}, Lot ${item.Lot_Number}, Plot ${item.Plot}`}</li>
            ))}
          </ul>
        </Alert>
      )}

      {parsedData.length > 0 && (
        <div>
          <Button
            variant="primary"
            onClick={handleSave}
            disabled={loading}
            style={{ marginBottom: "20px" }}
          >
            {loading ? (
              <Spinner animation="border" size="sm" />
            ) : (
              <FontAwesomeIcon icon={faSave} />
            )}{" "}
            Save Inventory
          </Button>

          <ProgressBar
            now={progress}
            label={`${Math.round(progress)}%`}
            animated={loading}
            striped={loading}
          />
        </div>
      )}
    </div>
  );
};

const containerStyle = {
  padding: "30px",
  backgroundColor: "#f8f9fa",
  borderRadius: "10px",
  boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
  width: "50%",
};

export default UploadSeedInventory;
