import React, { useEffect, useState } from "react";
import apiCalls from "../../Services/AxiosCalls";
import styled from "styled-components";
import { Link, useParams } from "react-router-dom";
import { Button, Form, Stack, Table } from "react-bootstrap";
import { useFlashMessage } from "../../Components /FlashMessageContext";
import OffcanvasComponent from "../../Components /OffcanvasComponent";
// import DataTable from "../../Components/DataTable";
// import CustomModal from "../../Components/CustomModal";
// import EditableTable from "../../Components/EditableTable";

const Ports = () => {
  const { param } = useParams();

  const [stations, setStations] = useState(null);
  const [selectedRowId, setSelectedRowId] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [createModal, setCreateModal] = useState(false);
  const [updatedName, setUpdatedName] = useState("");
  const [updatedDescription, setUpdatedDescription] = useState("");
  const [allunits, setAllUnits] = useState([]);

  const [newRow, setNewRow] = useState({
    name: "",
    description: "",
    type: "nu", // Default type
    unit: "dimensionless", // Default unit
    is_input: false,
  });

  const [ports, setPorts] = useState([]);

  const [formValue, setFormValue] = useState({
    name: "",
    type: "",
    description: "",
  });
  const { showFlashMessage } = useFlashMessage();

  useEffect(() => {
    getPorts();
  }, []);

  const getPorts = async () => {
    try {
      const response = await apiCalls.getblockPorts(param);
      setPorts(response.data);
      const customUnit = await apiCalls.getCommonUnit();
      setAllUnits(customUnit.data);
      console.log(customUnit.data);
    } catch (error) {
      console.error("Error fetching stations:", error);
      showFlashMessage("Error fetching stations", "danger");
    }
  };

  const headers = ["name", "description", "type"];

  const handleEdit = (rowIndex, field, value) => (e) => {
    console.log(value, field, rowIndex);
    const updatedData = [...ports];
    const newValue = e.target.value;

    // Check if the field is 'text_choices' and the new value contains a comma
    if (
      field === "text_choices" &&
      value.type === "ch" &&
      newValue.includes(",")
    ) {
      // Split the comma-separated values and trim them
      updatedData[rowIndex][field] = newValue
        .split(",")
        .map((value) => value.trim());
    } else if (
      field === "text_choices" &&
      value.type === "ch" &&
      !newValue.includes(",")
    ) {
      updatedData[rowIndex][field] = [newValue];
    } else if (!value.type === "ch") {
      updatedData[rowIndex]["text_choices"] = null;
    } else {
      updatedData[rowIndex][field] = newValue;
    }

    setPorts(updatedData);
    console.log(updatedData);
  };

  const handleAddPort = () => {
    // Create a new row object
    const newRowData = {
      name: "",
      description: "",
      type: "nu", // Default type
      unit: "dimensionless", // Default unit
      is_input: false,
    };
  
    // Append the new row to the ports array
    setPorts([...ports, newRowData]);
  };
  const handleRemoveRow = (rowIndex) => {
    const updatedData = [...ports];
    updatedData.splice(rowIndex, 1);
    setPorts(updatedData);
    console.log(updatedData);

  };

  const saveOrUpdateData = async (data) => {
    console.log(data)
    const results = [];

    for (const item of data) {
      try {
        if (item.id) {
          // If the object has an "id," send it to the update API
          const updatedItem = await apiCalls.updatePort(item.id, item);
          results.push(updatedItem);
        } else {
          // If the object doesn't have an "id," add "block_ID" and send it to the create API
          item.block = param; // Set your block ID here
          const createdItem = await apiCalls.savePorts(item);
          results.push(createdItem);
        }
      } catch (error) {
        console.error("Error processing an object:", error);
        // Handle the error here if needed
      }
    }

    console.log("All objects processed:", results);
    return results;
  };

  const actionFunction = async () => {
    try {
      const processedObjects = await saveOrUpdateData(ports);
      showFlashMessage(
        "All Ports have been processed successfully.",
        "success"
      );
      console.log(
        "All objects have been processed successfully.",
        processedObjects
      );
    } catch (error) {
      console.error("Error processing ports:", error);
      showFlashMessage("Error processing ports:", "danger");
    }
  };

  // Usage

  return (
    <>
      <div className="d-flex justify-content-start mb-3">
        <Button variant="success" onClick={handleAddPort}>
          Create New Port
        </Button>
      </div>
      {ports && (
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Name</th>
              <th>Description</th>
              <th>Type</th>
              <th>Unit</th>

              <th>Text Choices</th>
              <th>Action</th>

              {/* {ports.some((row) => row.type === "ch") && <th>Text Choices</th>} */}
            </tr>
          </thead>
          <tbody>
            {ports.map((row, rowIndex) => (
              <tr key={row.id}>
                <td>
                  <Form.Control
                    type="text"
                    value={row.name}
                    onChange={handleEdit(rowIndex, "name", row)}
                    required
                  />
                </td>
                <td>
                  <Form.Control
                    type="text"
                    value={row.description}
                    onChange={handleEdit(rowIndex, "description", row)}
                    required
                  />
                </td>
                <td>
                  <Form.Control
                    as="select"
                    value={row.type}
                    onChange={handleEdit(rowIndex, "type", row)}
                    required
                  >
                    <option value="nu">Numeric</option>
                    <option value="te">Text</option>
                    <option value="ch">Choices</option>
                  </Form.Control>
                </td>
                <td>
                  <Form.Control
                    as="select"
                    value={row.unit}
                    onChange={handleEdit(rowIndex, "unit", row)}
                    required
                  >
                    {allunits.map((option) => (
                      <option key={option} value={option}>
                        {option}
                      </option>
                    ))}
                  </Form.Control>
                </td>

                {/* {row.type === "ch" && (
                  <td>
                    <Form.Control
                      type="text"
                      value={row.text_choices}
                      onChange={handleEdit(rowIndex, "text_choices", row)}
                    />
                  </td>
                )} */}
                <td>
                  <Form.Control
                    type="text"
                    value={row.text_choices}
                    onChange={handleEdit(rowIndex, "text_choices", row)}
                    placeholder="Add choices separated by comma"
                    disabled={row.type !== "ch"} // Disable the input when row.type is not "ch"
                    required
                  />
                </td>

                <td>
                  <Button
                    variant="danger"
                    onClick={() => handleRemoveRow(rowIndex)}
                  >
                    Remove
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}
      <div className="d-flex justify-content-end mb-3">
        <Button variant="primary" onClick={actionFunction}>
          Submit{" "}
        </Button>
      </div>
      {/* <OffcanvasComponent
        title="Example Offcanvas"
        content={
          <div>
            <p>This is the content of the Offcanvas.</p>
            <p>You can customize it with your own content.</p>
          </div>
        }
        buttonText="Open Offcanvas"
      /> */}
      
    </>
  );
};

export default Ports;
