import React, { useEffect, useState } from "react";
import apiCalls from "../../Services/AxiosCalls";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { Button, Form } from "react-bootstrap";
import DataTable from "../../Components /DataTable";
import CustomModal from "../../Components /CustomModal";
import { useFlashMessage } from "../../Components /FlashMessageContext";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import UserForm from "./UserForm";

const Users = () => {
  const [stations, setStations] = useState(null);
  const [selectedRowId, setSelectedRowId] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [createModal, setCreateModal] = useState(false);
  const [updatedName, setUpdatedName] = useState("");
  const [updatedDescription, setUpdatedDescription] = useState("");

  const [formValue, setFormValue] = useState({
    first_name: selectedRowId?.first_name || "",
    last_name: selectedRowId?.last_name || "",
    username: selectedRowId?.username || "",
    email: selectedRowId?.email || "",
    is_superuser: selectedRowId?.is_superuser || false,
    is_active: selectedRowId?.is_active || false,
  });
  const { showFlashMessage } = useFlashMessage();

  useEffect(() => {
    getUsers();
  }, []);

  const getUsers = async () => {
    try {
      const response = await apiCalls.getAllDepartmentUSers();
      //console.log(response.data);
      setStations(response.data);
    } catch (error) {
      console.error("Error fetching stations:", error);
      showFlashMessage("Error fetching stations", "danger");
    }
  };

  const handleRowClick = (rowData) => {
    //console.log(rowData);
    setSelectedRowId(rowData);
    setFormValue(rowData);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setFormValue({
      ...formValue,
      first_name: "",
      last_name: "",
      username: "",
      email: "",
      is_superuser: false,
      is_active: false,
      password: "",
    });
  };

  const handleCreateCloseModal = () => {
    setCreateModal(false);
  };

  const handleUpdateName = async () => {
    try {
      //console.log(formValue);

      const response = await apiCalls.updateDepartmentUser(
        // formValue.id,
        formValue
      );

      if (response.status === 200) {
        showFlashMessage("Station Updated!", "success");
        setShowModal(false);
        getUsers();
      } else {
        console.error("Unexpected API response:", response);
        showFlashMessage("Error!", "danger");
      }
    } catch (error) {
      console.error("Error updating name:", error);
      showFlashMessage("Error Updating Station!", "danger");
    }
  };

  const handleAddStation = () => {
    setCreateModal(true);
  };

  const handleSaveUser = async () => {
    //console.log(formValue);
    try {
      const response = await apiCalls.postUser(formValue);
      if (response.status === 201) {
        //console.log(response.data);
        showFlashMessage("User Created!", "success");
        setCreateModal(false);
        getUsers();
      } else {
        console.error("Unexpected API response:", response);
        showFlashMessage("Error Creating User", "danger");
      }
    } catch (error) {
      console.error("Error creating user:", error);
      showFlashMessage("Error Creating User!", "danger");
    }
  };

  const rowEvents = {
    onClick: (e, row) => {
      handleRowClick(row);
    },
  };

  const columns = [
    {
      dataField: "first_name",
      text: "First Name",
      filter: textFilter(),
    },
    {
      dataField: "last_name",
      text: "Last Name",
      filter: textFilter(),
    },
    {
      dataField: "username",
      text: "Username",
      filter: textFilter(),
    },
    {
      dataField: "is_active",
      text: "Is Active",
      formatter: (cell, row) => (cell ? "YES" : "NO"),
      style: (cell, row) => ({
        color: cell ? "green" : "red",
      }),
      filter: textFilter(),
    },
    {
      dataField: "is_superuser",
      text: "Is Superuser",
      ilter: textFilter(),
      formatter: (cell, row) => (cell ? "YES" : "NO"),
      style: (cell, row) => ({
        color: cell ? "green" : "red",
      }),
      filter: textFilter(),
    },
    {
      dataField: "email",
      text: "Email",
    },
    // Add more columns as needed
  ];
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValue({
      ...formValue,
      [name]: value,
    });
  };

  return (
    <>
      <div className="d-flex justify-content-end mb-3">
        {/* <Button variant="success" onClick={handleAddStation}>
          Add User
        </Button> */}
      </div>
      {stations && (
        <BootstrapTable
          keyField="id"
          data={stations}
          columns={columns}
          pagination={paginationFactory({
            firstPageText: "First",
            prePageText: "Prev",
            nextPageText: "Next",
            lastPageText: "Last",
            nextPageTitle: "Go to next",
            backgroundColor: "#4E73DF",
          })}
          striped
          hover
          bordered
          wrapperClasses="table-responsive"
          filter={filterFactory()}
          rowEvents={rowEvents}

          // Make the table responsive
        />
      )}
      <CustomModal
        show={showModal}
        onHide={handleCloseModal}
        title={`Edit ${selectedRowId?.first_name} Details`}
        content={
          <Form onSubmit={handleAddStation}>
            <Form.Group controlId="first_name" className="mb-3">
              <Form.Label>First Name</Form.Label>
              <Form.Control
                type="text"
                name="first_name"
                value={formValue?.first_name}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group controlId="last_name" className="mb-3">
              <Form.Label>Last Name</Form.Label>
              <Form.Control
                type="text"
                name="last_name"
                value={formValue?.last_name}
                onChange={handleChange}
              />
            </Form.Group>
            {/* <Form.Group controlId="password" className="mb-3">
              <Form.Label>Password</Form.Label>
              <Form.Control
                type="password"
                name="password"
                value={selectedRowId?.password}
                // onChange={handleChange}
              />
            </Form.Group> */}
            <Form.Group controlId="username" className="mb-3">
              <Form.Label>Username</Form.Label>
              <Form.Control
                type="text"
                name="username"
                value={formValue?.username}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group controlId="email" className="mb-3">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                name="email"
                value={formValue?.email}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group controlId="is_superuser" className="mb-3">
              <Form.Check
                type="switch"
                name="is_superuser"
                label="Has all permissions"
                checked={formValue?.is_superuser}
                onChange={(e) =>
                  setFormValue({ ...formValue, is_superuser: e.target.checked })
                }
              />
            </Form.Group>
            <Form.Group controlId="is_active" className="mb-3">
              <Form.Check
                type="switch"
                name="is_active"
                label="Is Active"
                checked={formValue?.is_active}
                onChange={(e) =>
                  setFormValue({ ...formValue, is_active: e.target.checked })
                }
              />
            </Form.Group>
          </Form>
        }
        btnName="Update"
        onSave={handleUpdateName}
      />
      <CustomModal
        show={createModal}
        onHide={handleCreateCloseModal}
        title="Create User"
        content={
          <Form onSubmit={handleAddStation}>
            <Form.Group controlId="first_name" className="mb-3">
              <Form.Label>First Name</Form.Label>
              <Form.Control
                type="text"
                name="first_name"
                value={formValue.first_name}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group controlId="last_name" className="mb-3">
              <Form.Label>Last Name</Form.Label>
              <Form.Control
                type="text"
                name="last_name"
                value={formValue.last_name}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group controlId="password" className="mb-3">
              <Form.Label>Password</Form.Label>
              <Form.Control
                type="password"
                name="password"
                value={formValue.password}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group controlId="username" className="mb-3">
              <Form.Label>Username</Form.Label>
              <Form.Control
                type="text"
                name="username"
                value={formValue.username}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group controlId="email" className="mb-3">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                name="email"
                value={formValue.email}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group controlId="is_superuser" className="mb-3">
              <Form.Check
                type="switch"
                name="is_superuser"
                label="Has all permissions"
                checked={formValue.is_superuser}
                onChange={(e) =>
                  setFormValue({ ...formValue, is_superuser: e.target.checked })
                }
              />
            </Form.Group>
            <Form.Group controlId="is_active" className="mb-3">
              <Form.Check
                type="switch"
                name="is_active"
                label="Is Active"
                checked={formValue.is_active}
                onChange={(e) =>
                  setFormValue({ ...formValue, is_active: e.target.checked })
                }
              />
            </Form.Group>
          </Form>
        }
        btnName="Save"
        onSave={handleSaveUser}
      />
    </>
  );
};

export default Users;
