import React, { useEffect, useState } from "react";
import apiCalls from "../../Services/AxiosCalls";
import { Button, Form } from "react-bootstrap";
import DataTable from "../../Components /DataTable";
import CustomModal from "../../Components /CustomModal";
import { useFlashMessage } from "../../Components /FlashMessageContext";
import Select from "react-select";

const Protocols = () => {
  const [stations, setStations] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [createModal, setCreateModal] = useState(false);
  const [allBlocks, setAllBlocks] = useState([]);

  const [formValue, setFormValue] = useState({
    name: "",
    description: "",
    blocks: [],
  });

  const { showFlashMessage } = useFlashMessage();

  useEffect(() => {
    getUsers();
  }, []);

  const getUsers = async () => {
    try {
      const [response, blocks] = await Promise.all([
        apiCalls.getProtocol(),
        apiCalls.getAllMachines(),
      ]);

      const modifiedBlocks = blocks.data.map((block) => ({
        value: block.id,
        label: block.name,
      }));
      //console.log(response)
      setStations(response.data);
      setAllBlocks(modifiedBlocks);
    } catch (error) {
      console.error("Error fetching stations:", error);
      showFlashMessage("Error fetching stations", "danger");
    }
  };

  const handleRowClick = (rowData) => {
    //console.log(rowData);
    setSelectedRow(rowData);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleCreateCloseModal = () => {
    setCreateModal(false);
  };

  const handleUpdateName = async () => {

    try {
      if (!selectedRow || !selectedRow.name) {
        console.error("Invalid data for update.");
        showFlashMessage("Invalid data for update", "danger");
        return;
      }

      const response = await apiCalls.updateProtocol(
        selectedRow.id,
        selectedRow
      );

      if (response.status === 200) {
        showFlashMessage("Protocol Updated!", "success");
        setShowModal(false);
        getUsers();
      } else {
        console.error("Unexpected API response:", response);
        showFlashMessage("Error!", "danger");
      }
    } catch (error) {
      console.error("Error updating name:", error);
      showFlashMessage("Error Updating Station!", "danger");
    }
  };

  const handleAddStation = () => {
    setCreateModal(true);
  };

  const handleSaveStation = async () => {
    try {
      const response = await apiCalls.postProtocol(formValue);
      if (response.status === 201) {
        showFlashMessage("Protocol Created!", "success");
        setCreateModal(false);
        getUsers();
      } else {
        console.error("Unexpected API response:", response);
        showFlashMessage("Error Creating Protocol", "danger");
      }
    } catch (error) {
      console.error("Error creating station:", error);
      showFlashMessage("Error Creating Protocol!", "danger");
    }
  };

  const headers = ["name", "description"];

  return (
    <>
      <div className="d-flex justify-content-end mb-3">
        <Button variant="success" onClick={handleAddStation}>
          Create New Protocol
        </Button>
      </div>
      {stations.length > 0 && (
        <DataTable
          headers={headers}
          data={stations}
          onRowClick={handleRowClick}
        />
      )}

      <CustomModal
        show={showModal}
        onHide={handleCloseModal}
        title="Edit Protocol"
        content={
          <Form>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter name"
                value={selectedRow?.name}
                onChange={(e) =>
                  setSelectedRow({ ...selectedRow, name: e.target.value })
                }
              />
              <Form.Text className="text-muted">
                You can edit and update the Station name
              </Form.Text>
            </Form.Group>
            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Label>Blocks</Form.Label>
              <Select
                aria-label="Default select example"
                onChange={(selectedOptions) =>
                  setSelectedRow((prevFormValue) => ({
                    ...prevFormValue,
                    blocks: selectedOptions.map((option) => option.value),
                  }))
                }
                options={allBlocks}
                value={allBlocks
                  .filter(
                    (option) => selectedRow?.blocks.indexOf(option.value) !== -1
                  )
                  .map((option) => ({
                    value: option.value,
                    label: option.label,
                  }))}
                isMulti
              />
            </Form.Group>
            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Control
                as="textarea"
                rows={3}
                placeholder="Description"
                value={selectedRow?.description}
                onChange={(e) =>
                  setSelectedRow({
                    ...selectedRow,
                    description: e.target.value,
                  })
                }
              />
            </Form.Group>
          </Form>
        }
        btnName="Update"
        onSave={handleUpdateName}
      />
      <CustomModal
        show={createModal}
        onHide={handleCreateCloseModal}
        title="Create Protocol"
        content={
          <Form noValidate validated="false">
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Control
                type="text"
                placeholder="Protocol name"
                value={formValue.name}
                onChange={(e) =>
                  setFormValue({ ...formValue, name: e.target.value })
                }
                required
              />
            </Form.Group>
            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
              
            >
              <Select
                aria-label="Default select example"
                onChange={(selectedOptions) =>
                  setFormValue((prevFormValue) => ({
                    ...prevFormValue,
                    blocks: selectedOptions.map((option) => option.value),
                  }))
                }
                options={allBlocks}
                placeholder="Select blocks"
                isMulti
                required
              />
            </Form.Group>
            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Control
                as="textarea"
                rows={3}
                placeholder="Description"
                value={formValue.description}
                onChange={(e) =>
                  setFormValue({ ...formValue, description: e.target.value })
                }
              />
            </Form.Group>
          </Form>
        }
        btnName="Save"
        onSave={handleSaveStation}
      />
    </>
  );
};

export default Protocols;
