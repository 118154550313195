import React, { lazy, Suspense, useEffect, useState } from "react";
import { Row, Col, Button, Form } from "react-bootstrap";
import { useLocation, useParams } from "react-router-dom";
import CustomModal from "../../Components /CustomModal";
import Select from "react-select";
import apiCalls from "../../Services/AxiosCalls";
import { useFlashMessage } from "../../Components /FlashMessageContext";
import { helix, cardio } from "ldrs";

helix.register();
cardio.register();

const TasksTable = lazy(() => import("../../Components /TasksTable"));

const ProjectDetails = () => {
  const { param } = useParams();
  const { state } = useLocation();

  const { showFlashMessage } = useFlashMessage();

  const [showModal, setShowModal] = useState(false);
  const [items, setItems] = useState(null);
  const [protocol, setProtocol] = useState(null);
  const [projectsData, setAllProjectData] = useState(null);

  const [asignee, setAsignee] = useState(null);
  const [projectTasks, setProjectTasks] = useState(null);
  const [projectData, setProjectData] = useState({
    item: null,
    protocol: null,
    assignee: null,
    description: null,
    name: null,
    done: false,
    iteration: null,
    project: param,
  });
  const [isLoading, setIsLoading] = useState(true);

  const projectObject = state && state.project;

  useEffect(() => {
    getInitialData();
  }, [param]);

  const getInitialData = async () => {
    try {
      const [
        itemsResponse,
        protocolResponse,
        usersResponse,
        projectTaskss,
        allProjects,
      ] = await Promise.all([
        apiCalls.getAllItems(),
        apiCalls.getProtocol(),
        apiCalls.getAllUSers(),
        apiCalls.getProjectTasks(param),
        apiCalls.getProject(),
      ]);

      setIsLoading(true);

      const usersOptions = usersResponse.data.map((data) => ({
        label: data.username,
        value: data.id,
      }));

      const itemOptions = itemsResponse.data.map((data) => ({
        label: data.name,
        value: data.id,
      }));

      const protocolOptions = protocolResponse.data.map((data) => ({
        label: data.name,
        value: data.id,
      }));
      const projectOptions = allProjects.data.map((data) => ({
        label: data.name,
        value: data.id,
      }));

      setAsignee(usersOptions);
      setItems(itemOptions);
      setProtocol(protocolOptions);
      setProjectTasks(projectTaskss.data);
      setAllProjectData(projectOptions);
      //console.log(state.project.name);

      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching initial data:", error);
    }
  };

  if (!projectObject) {
    return (
      <div>
        <p>Project data not found.</p>
      </div>
    );
  }

  const handleAddStation = async () => {
    setShowModal(true);
  };

  const handleCloseModal = async () => {
    setProjectData((prevData) => ({
      ...projectData,
      item: null,
      protocol: null,
      assignee: null,
      project: null,
      description: null,
      done: false,
      iteration: null,
      name: null,
    }));

    setShowModal(false);
  };

  const saveItems = async () => {
    try {
      const newTaskResponse = await apiCalls.postTask(projectData);

      if (newTaskResponse.status === 201) {
        showFlashMessage(
          `Task ${projectData.name} saved successfully`,
          "success"
        );
        setProjectTasks((prevProjects) => [
          ...projectTasks,
          newTaskResponse.data,
        ]);
        handleCloseModal();
      }
    } catch (error) {
      console.error("Error saving task:", error);
      showFlashMessage("Error saving task. Please try again.", "error");
    }
  };

  return (
    <div>
      <div className="d-flex justify-content-start mb-3 ">
        <h5>{state.project.name}</h5>
      </div>
      <div className="d-flex justify-content-end mb-3 ">
        <Button
          variant="success"
          onClick={handleAddStation}
          style={{ marginLeft: 12 }}
        >
          Add New Task
        </Button>
      </div>

      <CustomModal
        show={showModal}
        onHide={handleCloseModal}
        title="Add Task To Project"
        content={
          <Form>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Task Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter name"
                onChange={(e) =>
                  setProjectData((prevData) => ({
                    ...projectData,
                    name: e.target.value,
                  }))
                }
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Item</Form.Label>
              <Select
                options={items}
                onChange={(e) =>
                  setProjectData((prevData) => ({
                    ...projectData,
                    item: e.value,
                  }))
                }
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Protocol</Form.Label>
              <Select
                options={protocol}
                onChange={(e) =>
                  setProjectData((prevData) => ({
                    ...projectData,
                    protocol: e.value,
                  }))
                }
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Project</Form.Label>
              <Select
                options={projectsData}
                onChange={(e) =>
                  setProjectData((prevData) => ({
                    ...projectData,
                    project: e.value,
                  }))
                }
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Assignee</Form.Label>
              <Select
                options={asignee}
                onChange={(e) =>
                  setProjectData((prevData) => ({
                    ...projectData,
                    assignee: e.value,
                  }))
                }
              />
            </Form.Group>

            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Control
                as="textarea"
                rows={3}
                placeholder="Description"
                onChange={(e) =>
                  setProjectData((prevData) => ({
                    ...projectData,
                    description: e.target.value,
                  }))
                }
              />
            </Form.Group>
          </Form>
        }
        btnName="Save"
        onSave={saveItems}
      />
      {isLoading ? (
        <div className="d-flex justify-content-center align-items-center">
          <l-square size="50" speed="0.9" color="#354052"></l-square>
        </div>
      ) : (
        <Suspense fallback={<div>Loading...</div>}>
          <TasksTable
            tasks={projectTasks}
            asignee={asignee}
            items={items}
            protocol={protocol}
            project={projectsData}
            onCall={getInitialData}
          />
        </Suspense>
      )}
    </div>
  );
};

export default ProjectDetails;
