import React from "react";
import LimsNexusDescription from "./LimsNexusDescription";

const Docs = () => {
  return (
    <>
    <LimsNexusDescription/>
    {/* <div style={styles.container}>
      <h1 style={styles.title}>Step-by-Step Guide: Setting Up the LIMS Architecture</h1>

      <section style={styles.section}>
        <h2 style={styles.sectionTitle}>1. Logging In</h2>
        <ol style={styles.stepList}>
          <li style={styles.stepItem}>Open the application and navigate to the login page.</li>
          <li style={styles.stepItem}>Enter your email address associated with the Seed Nexus system.</li>
          <li style={styles.stepItem}>Enter your password.</li>
          <li style={styles.stepItem}>Click the "Login" button. Upon successful login, you will be redirected to the main dashboard of the LIMS application.</li>
        </ol>
      </section>

      <section style={styles.section}>
        <h2 style={styles.sectionTitle}>2. Setting Up LIMS Architecture</h2>
        <p style={styles.description}>
          The architecture of the LIMS is foundational to its functionality. This section guides you through setting up Stations, Blocks, and Ports.
        </p>

        <h3 style={styles.subSectionTitle}>2.1 Creating Stations</h3>
        <ol style={styles.stepList}>
          <li style={styles.stepItem}>Navigate to the "Stations" section from the settings menu.</li>
          <li style={styles.stepItem}>Click on "Create New Station."</li>
          <li style={styles.stepItem}>Enter the Station Name to identify the station.</li>
          <li style={styles.stepItem}>
            Select the Station Type:
            <ul style={styles.nestedList}>
              <li style={styles.nestedItem}><strong>Block:</strong> Choose this option if the station will not handle image data.</li>
              <li style={styles.nestedItem}><strong>Image:</strong> Choose this option if the station will manage and analyze image data.</li>
            </ul>
          </li>
          <li style={styles.stepItem}>Click "Save" to create the station.</li>
        </ol>

        <h3 style={styles.subSectionTitle}>2.2 Creating Blocks</h3>
        <ol style={styles.stepList}>
          <li style={styles.stepItem}>After creating the station, move to the "Blocks" section.</li>
          <li style={styles.stepItem}>Click on "Create New Block."</li>
          <li style={styles.stepItem}>Enter the Block Name to identify the block.</li>
          <li style={styles.stepItem}>Select the Station from the list of stations that will contain this block.</li>
          <li style={styles.stepItem}>Click "Save" to create the block.</li>
        </ol>

        <h3 style={styles.subSectionTitle}>2.3 Creating Ports</h3>
        <ol style={styles.stepList}>
          <li style={styles.stepItem}>Now, proceed to the "Ports" section.</li>
          <li style={styles.stepItem}>Click on "View Port from the Block page."</li>
          <li style={styles.stepItem}>Select a Block to create its Ports."</li>
          <li style={styles.stepItem}>Click on "Get Ports"</li>
          <li style={styles.stepItem}>Click on "Create New Ports"</li>
          <li style={styles.stepItem}>Enter the Port Name to identify the port.</li>
          <li style={styles.stepItem}>Specify the Unit of measurement (e.g., grams, cm).</li>
          <li style={styles.stepItem}>
            Select the Port Data Type:
            <ul style={styles.nestedList}>
              <li style={styles.nestedItem}><strong>Numeric:</strong> For data that will be numerical.</li>
              <li style={styles.nestedItem}><strong>Text:</strong> For data that will be in text format.</li>
              <li style={styles.nestedItem}><strong>Choices:</strong> If the data type is a set of predefined choices.</li>
            </ul>
          </li>
          <li style={styles.stepItem}>
            If <strong>Choices</strong> is selected, provide the list of choices separated by commas (e.g., "Option 1, Option 2, Option 3").
          </li>
          <li style={styles.stepItem}>Click "Save" to create the port.</li>
        </ol>
      </section>

      <section style={styles.section}>
        <h2 style={styles.sectionTitle}>3. Additional Options</h2>
        <p style={styles.description}>Apart from the basic architecture, users have the ability to create Formulas and Protocols:</p>

        <h3 style={styles.subSectionTitle}>3.1 Creating Formulas</h3>
        <ol style={styles.stepList}>
          <li style={styles.stepItem}>Go to the "Formulas" section.</li>
          <li style={styles.stepItem}>Click on "Create New Formula."</li>
          <li style={styles.stepItem}>Define the formula by specifying the inputs and the desired computation.</li>
          <li style={styles.stepItem}>Save the formula for future use.</li>
        </ol>

        <h3 style={styles.subSectionTitle}>3.2 Creating Protocols</h3>
        <ol style={styles.stepList}>
          <li style={styles.stepItem}>Navigate to the "Protocols" section.</li>
          <li style={styles.stepItem}>Click on "Create New Protocol."</li>
          <li style={styles.stepItem}>Provide a protocol name and detailed steps or procedures.</li>
          <li style={styles.stepItem}>Save the protocol for application in relevant processes.</li>
        </ol>
      </section>
    </div> */}
    </>

  );
};

const styles = {
  container: {
    maxWidth: "800px",
    // margin: "0 auto",
    padding: "10px",
    backgroundColor: "#f7f8fa",
    borderRadius: "10px",
    boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
    fontFamily: "Arial, sans-serif",
    lineHeight: "1.6",
  },
  title: {
    textAlign: "center",
    fontSize: "2rem",
    color: "#256EFF",
    marginBottom: "20px",
  },
  section: {
    marginBottom: "30px",
  },
  sectionTitle: {
    fontSize: "1.5rem",
    color: "#333",
    marginBottom: "15px",
  },
  subSectionTitle: {
    fontSize: "1.25rem",
    color: "#256EFF",
    marginTop: "20px",
    marginBottom: "10px",
  },
  stepList: {
    paddingLeft: "20px",
  },
  stepItem: {
    marginBottom: "10px",
    fontSize: "1rem",
    color: "#555",
  },
  description: {
    fontSize: "1rem",
    color: "#777",
    marginBottom: "20px",
  },
  nestedList: {
    paddingLeft: "20px",
  },
  nestedItem: {
    fontSize: "1rem",
    color: "#555",
  },
};

export default Docs;
