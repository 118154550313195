import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Badge,
  Button,
  Col,
  Form,
  Offcanvas,
  Row,
  Stack,
} from "react-bootstrap";
import Select from "react-select";

import InventoryOffCanvas from "./InventoryOffCanvas";
import CreateStorages from "./CreateStorage";
import apiCalls from "../../Services/AxiosCalls";
import InventoryDataSource from "../../Components /InventoryDataSource";
import Transactions from "./Transactions";
import QRScanner from "../../Components /QRScanner";
import QrScanner from "qr-scanner";
import UploadSeedInventory from "../../Components /UploadSeedInventory";

const Inventory = () => {
  const navigate = useNavigate();
  const [showOffCanvas, setShowOffCanvas] = useState(false);
  const toggleOffCanvas = () => setShowOffCanvas(!showOffCanvas);
  const [filteresData, setFilteresData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [showFilter, setShowFilter] = useState(false);
  const [params, setParams] = useState({});
  const [submitLoading, setSubmitLoading] = useState(false);
  const [tableData, setTableData] = useState(null);
  const [transactions, setTransactions] = useState(false);
  const [activeComponent, setActiveComponent] = useState("transactions");
  const [showQRScanner, setShowQRScanner] = useState(false);
  const videoRef = useRef(null);
  const [scannedData, setScannedData] = useState("");
  const [showScanner, setShowScanner] = useState(false);

  useEffect(() => {
    // getFilteredData();
    getExtractedFilteres();
  }, []);
  const toggleFilter = () => {
    setShowFilter(!showFilter);
  };

  const getExtractedFilteres = async () => {
    try {
      const response = await apiCalls.ExtractFilteres();
      //console.log(response.data);
      setFilteresData(response.data);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const handleGetSelectedOptions = async () => {
    const BL_block_ids =
      params.block_id?.filter((item) => item.type === "BL").map((item) => item.value) || [];
    const IM_block_ids =
      params.block_id?.filter((item) => item.type === "IM").map((item) => item.value) || [];
  
    console.log(BL_block_ids, IM_block_ids);
  
    try {
      let combinedResults = [];
      const commonParams = {
        project_id: params.project_id?.value || null,
        crop: params.crop?.value || null,
        csi: params.csi?.value || null,
        farm: params.farm?.value || null,
        trial: params.trial?.value || null,
        year: params.year?.value || null,
        location: params.location?.value || null,
      };
  
      // If both BL and IM block IDs are empty, make a single request
      if (BL_block_ids.length === 0 && IM_block_ids.length === 0) {
        const BL_params = { ...commonParams, block_id: null };
        console.log(BL_params);
  
        const [BL_response, IM_response] = await Promise.all([
          apiCalls.FilteredeReport(BL_params),
          apiCalls.FilteredImageReport(BL_params),
        ]);
  
        combinedResults = combinedResults.concat(BL_response.data, IM_response.data);
      } else {
        // Make requests for each block type (BL and IM) if block IDs exist
        const promises = [];
  
        if (BL_block_ids.length > 0) {
          const BL_params = {
            ...commonParams,
            block_id: BL_block_ids.join(","),
          };
          console.log(BL_params);
          promises.push(apiCalls.FilteredeReport(BL_params));
        }
  
        if (IM_block_ids.length > 0) {
          const IM_params = {
            ...commonParams,
            block_id: IM_block_ids.join(","),
          };
          console.log(IM_params);
          promises.push(apiCalls.FilteredImageReport(IM_params));
        }
  
        const results = await Promise.all(promises);
  
        // Handle multiple responses
        if (results[0]) {
          combinedResults = combinedResults.concat(results[0].data);
        }
        if (results[1]) {
          const objectsWithFalseAvgStats = results[1].data.filter(
            (obj) => !obj.has_avg_stats_out
          );
          combinedResults = combinedResults.concat(objectsWithFalseAvgStats);
        }
      }
  
      // Transform data and set to table
      const transformedData = transformData(combinedResults);
      console.log(transformedData);
  
      if (transformedData.length === 0) {
        setTableData([{ noDataFound: true }]);
      } else {
        setTableData(transformedData);
      }
    } catch (error) {
      console.error("Error fetching selected options data:", error);
    } finally {
      toggleFilter();
      setActiveComponent("dataSource");
    }
  };
  


  function transformData(inputData) {
    const groupedData = {};
    const entriesWithoutStations = [];
  
    inputData.forEach((entry) => {
      const { stations, block, task, record_data, data_dict, task_name, iteration, item } = entry;
      console.log(stations)
  
      if (stations !== undefined) {
        const key = `${stations}-${block}`;
        if (!groupedData[key]) {
          groupedData[key] = { station: stations, block, data: [] };
        }
        const existingTask = groupedData[key].data.find((item) => item.task === task);
  
        if (existingTask) {
          existingTask.records.push(record_data);
        } else {
          groupedData[key].data.push({
            task,
            data_dict,
            task_name,
            stations,
            block,
            iteration,
            item,
            records: [record_data],
          });
        }
      } else {
        console.error("Entry without stations:", entry);
        entriesWithoutStations.push(entry);
      }
    });
  
    return Object.values(groupedData).map(({ station, block, data }) => [{ station, block, data }]);
  }
  

    // Log entries without stations
    //console.log("Entries without stations:", entriesWithoutStations);

  

  const transactionsOopen = () => {
    setActiveComponent("transactions");
  };
  const toggleQRScanner = () => {
    setShowQRScanner(!showQRScanner);
  };

  // Render active component based on activeComponent state
  const renderActiveComponent = () => {
    switch (activeComponent) {
      case "dataSource":
        return <InventoryDataSource data={tableData} />;
      case "transactions":
        return <Transactions />;
      // case "Shelves":
      //   return <ShelfForm />;
      // case "Boxes":
      //   return <BoxForm />;
      default:
        return null;
    }
  };

  const scanQRCode = async () => {
    try {
      setShowScanner(true);
      const scanner = new QrScanner(videoRef.current, (result) => {
        setScannedData(result);
        scanner.stop();
      });
      scanner.start();
    } catch (error) {
      console.error("Error scanning QR code:", error);
    }
  };

  return (
    <>
      <Stack direction="horizontal" gap={3}>
        <Button variant="primary" onClick={toggleFilter}>
          Import Data
        </Button>
        {/* <Button variant="primary" onClick={transactionsOopen}>
          Inventory
        </Button> */}
        {/* <Button variant="primary" onClick={toggleOffCanvas}>
          Orders
        </Button> */}
      </Stack>
      <hr />
      {tableData?.length > 0 && tableData[0]?.[0]?.data && (
        <UploadSeedInventory blockData={tableData[0][0].data} />
      )}

      {renderActiveComponent()}

      {filteresData !== null && (
        <Offcanvas show={showFilter} onHide={toggleFilter}>
          <Offcanvas.Header closeButton>
            <Offcanvas.Title>Import Inventory Data</Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body style={{ overflow: "inherit" }}>
            <Row>
              <Col xs={12} style={{ marginBottom: "5px" }}>
                {/* Select for Block */}
                <Select
                  isMulti
                  options={filteresData.Block.map((item) => ({
                    value: item[0],
                    label: item[1],
                    type: item[2],
                  }))}
                  placeholder="Block"
                  onChange={(selectedOptions) =>
                    setParams({ ...params, block_id: selectedOptions })
                  }
                  value={params.block_id || null}
                  isClearable={true}
                />
              </Col>
              <Col xs={12} style={{ marginBottom: "5px" }}>
                {/* Select for Project */}
                <Select
                  options={filteresData.Project.map((item) => ({
                    value: item[0],
                    label: item[1],
                  }))}
                  placeholder="Project"
                  onChange={(selectedOptions) =>
                    setParams({ ...params, project_id: selectedOptions })
                  }
                  value={params.project_id || null}
                  isClearable={true}
                />
              </Col>
              <Col xs={12} style={{ marginBottom: "7px" }}>
                {/* Select for Tasks */}
                <Select
                  options={filteresData.Task.map((item) => ({
                    value: item[0],
                    label: item[1],
                  }))}
                  placeholder="Tasks"
                  onChange={(selectedOptions) =>
                    setParams({ ...params, task_id: selectedOptions })
                  }
                  value={params.task_id || null}
                  isClearable={true}
                />
              </Col>
              <Col xs={12} style={{ marginBottom: "5px" }}>
                {/* Select for Crop */}
                <Select
                  options={filteresData.Crop.map((item) => ({
                    value: item[0],
                    label: item[1],
                  }))}
                  placeholder="Crop"
                  onChange={(selectedOptions) =>
                    setParams({ ...params, crop: selectedOptions })
                  }
                  value={params.crop || null}
                  isClearable={true}
                />
              </Col>
              <Col xs={12} style={{ marginBottom: "5px" }}>
                {/* Select for Farm */}
                <Select
                  options={filteresData.Farm.map((item) => ({
                    value: item[0],
                    label: item[1],
                  }))}
                  placeholder="Farm"
                  onChange={(selectedOptions) =>
                    setParams({ ...params, farm: selectedOptions })
                  }
                  value={params.farm || null}
                  isClearable={true}
                />
              </Col>
              <Col xs={12} style={{ marginBottom: "5px" }}>
                {/* Select for CSI */}
                <Select
                  options={filteresData.CSI.map((item) => ({
                    value: item[0],
                    label: item[1],
                  }))}
                  placeholder="CSI"
                  onChange={(selectedOptions) =>
                    setParams({ ...params, csi: selectedOptions })
                  }
                  value={params.csi || null}
                  isClearable={true}
                />
              </Col>
              <Col xs={12} style={{ marginBottom: "5px" }}>
                {/* Select for Trial */}
                <Select
                  options={filteresData.Trial.map((item) => ({
                    value: item[0],
                    label: item[1],
                  }))}
                  placeholder="Trial"
                  onChange={(selectedOptions) =>
                    setParams({ ...params, trial: selectedOptions })
                  }
                  value={params.trial || null}
                  isClearable={true}
                />
              </Col>
              <Col xs={12} style={{ marginBottom: "5px" }}>
                {/* Select for Year */}
                <Select
                  options={filteresData.Year.map((item) => ({
                    value: item[0],
                    label: item[1],
                  }))}
                  placeholder="Year"
                  onChange={(selectedOptions) =>
                    setParams({ ...params, year: selectedOptions })
                  }
                  value={params.year || null}
                  isClearable={true}
                />
              </Col>
              <Col xs={12} style={{ marginBottom: "5px" }}>
                {/* Select for Year */}
                <Select
                  options={filteresData.Location.map((item) => ({
                    value: item[0],
                    label: item[1],
                  }))}
                  placeholder="Location"
                  onChange={(selectedOptions) =>
                    setParams({ ...params, location: selectedOptions })
                  }
                  value={params.location || null}
                  isClearable={true}
                />
              </Col>
            </Row>
            <Row>
              <Stack
                direction="horizontal"
                gap={3}
                style={{
                  marginBottom: "5px",
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <Button
                  variant="secondary"
                  onClick={async () => {
                    setSubmitLoading(true); // Set loading state to true when submitting
                    await handleGetSelectedOptions(); // Call submit function
                    setSubmitLoading(false); // Set loading state to false when done
                  }}
                  disabled={submitLoading} // Disable button when loading
                >
                  {submitLoading ? "Fetching Data..." : "Fetch"}
                </Button>
                <div className="vr" />
                <Button variant="outline-danger" onClick={toggleFilter}>
                  Cancel
                </Button>
              </Stack>
            </Row>
          </Offcanvas.Body>
        </Offcanvas>
      )}
    </>
  );
};

export default Inventory;
