import React, { useEffect, useState } from "react";
import apiCalls from "../Services/AxiosCalls";
import styled from "styled-components";
import { Grid } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  
  faInfo,
  faProjectDiagram,
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import ProcessTrendReport from "../Components /ProcessTrendReport";
import { Button, Col, Row } from "react-bootstrap";
import { helix, cardio } from "ldrs";
import PieChart from "../Components /PieChart";
import Select from "react-select";
import LineChart from "../Components /LineChart";
import QuadrantLayout from "../Components /QuadrantLayout";
import { faArrowProgress, faBlock, faFunction, faHardDrive, faInventory,faCabinetFiling, faDiagramProject, faImage} from "@fortawesome/pro-solid-svg-icons";
import CustomCard from '../Components /CustomCard';
import QRCodeExtractor from "../Components /QRCodeExtractor";
import { createTheme } from '@mui/material/styles'; 



helix.register();
cardio.register();
const theme = createTheme();


const CACHE_KEY = "cachedTimeRecords";

const Dashboard = () => {
  const [stations, setStations] = useState(null);
  const [taskCompleteLoader, setTaskCompleteLoader] = useState(true);
  const [imageDataLoading, setImageDataLoading] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [pieChartData, setPieChartData] = useState(null);
  const [imagePieChartData, setImagePieChartData] = useState(null);
  const [allTasks, setAllTasks] = useState(null);
  const [selectedTimeFilters, setSelectedTimeFilters] = useState([]);
  const [selectedProjectFilters, setSelectedProjectFilters] = useState([]);
  const [taskData, setTaskData] = useState([]);
  const [dashCounts, setDashCounts] = useState([]);

  useEffect(() => {
    getUsers();
    fetchProjectTaskCompleteData();
    getImageStats();
    getProjects();
    fetchTaskCountData();
    getDashCounts()
  }, [selectedProjectFilters, selectedTimeFilters]);

  const getProjects = async () => {
    try {
      const response = await apiCalls.getProject();
      const taskOptions = response.data.map((data) => ({
        label: data.name,
        value: data.id,
      }));
      setAllTasks(taskOptions);
    } catch (error) {
      console.error("Error fetching projects:", error);
    }
  };
  const getDashCounts = async() =>{
    try {
      const result = await apiCalls.getDashCounts()
      setDashCounts(result?.data)
      //console.log(result)
    } catch (error) {
      
    }
  }

  const fetchTaskCountData = async () => {
    setIsLoading(true);
    try {
      const response = await apiCalls.getTaskTrend({
        time_interval: selectedTimeFilters.value,
        project_id: selectedProjectFilters.value,
      });
      setTaskData(response.data.data);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching trend data:", error);
      setIsLoading(false);
    }
  };

  const fetchProjectTaskCompleteData = async () => {
    setTaskCompleteLoader(true);
    try {
      const response = await apiCalls.getProjectTaskCompleteReport({
        time_interval: selectedTimeFilters.value,
        project_id: selectedProjectFilters.value,
      });
      setPieChartData(response.data.pie_chart_data);
      setTaskCompleteLoader(false);
    } catch (error) {
      console.error("Error fetching project task complete data:", error);
      setTaskCompleteLoader(false);
    }
  };

  const getImageStats = async () => {
    setImageDataLoading(true);
    try {
      const response = await apiCalls.getImageStats({
        time_interval: selectedTimeFilters.value,
        project_id: selectedProjectFilters.value,
      });
      setImagePieChartData(response.data.chart_data);
      setImageDataLoading(false);
    } catch (error) {
      console.error("Error fetching image stats:", error);
      setImageDataLoading(false);
    }
  };

  const getUsers = async () => {
    try {
      const users = await apiCalls.getAllStations();
      setStations(users.data);
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  const handleFilterChange = (selectedOptions) => {
    setSelectedProjectFilters(selectedOptions);
  };

  const handleTimeFilterChange = (selectedOptions) => {
    setSelectedTimeFilters(selectedOptions);
  };

  const icon1 = <FontAwesomeIcon icon={faCabinetFiling} className="mr-2" />;
  const images = <FontAwesomeIcon icon={faImage} className="mr-2" />;
  const projectsIcon = <FontAwesomeIcon icon={faDiagramProject} className="mr-2" />;
  // const crops = <FontAwesomeIcon icon={faLeaf} className="mr-2" />;
  const protocol = <FontAwesomeIcon icon={faArrowProgress} className="mr-2" />;
  const inventory = <FontAwesomeIcon icon={faInventory} className="mr-2" />;

  const cardsData = [
    { name: "Records", icon: icon1, to: "/reports", color:'#1cc88a', count: dashCounts?.records ? dashCounts.records : 0}, // Add a 'to' prop for the route
    { name: "Images", icon: images, to: "/images", color:'#e74a3b', count: dashCounts?.imaging_record_sets ? dashCounts.imaging_record_sets: 0 },
    { name: "Projects", icon: projectsIcon, to: "/projects", color:'#36b9cc', count: dashCounts?.projects ? dashCounts.projects: 0 },
    // { name: "Crops", icon: crops, to: "/settings/crops" },
    // { name: "PROTOCOLS", icon: protocol, to: "/settings/protocols" },
    { name: "Inventory", icon: inventory, to: "/inventory" ,color:'#f6c23e', count: 'View'},
  ];

  const loadingIndicator = (
    <div className="d-flex justify-content-center align-items-center">
      <l-cardio size="45" speed="2.5" color="black"></l-cardio>
      <p>computing.....</p>
    </div>
  );

  return (
    <>
      <Row style={{ marginBottom: 28, margin: theme.spacing(1) }}>
      <Col xs={12} sm={6} md={4} lg={3}>
        <Select
          options={allTasks}
          onChange={handleFilterChange}
          placeholder="Select Project"
          isClearable
        />
      </Col>
      <Col xs={12} sm={6} md={4} lg={3} className="mt-2 mt-sm-0">
        <Select
          options={[
            { value: "year", label: "Year" },
            { value: "month", label: "Month" },
            { value: "week", label: "Week" },
          ]}
          onChange={handleTimeFilterChange}
          placeholder="Select Time Interval"
          isClearable
        />
      </Col>
    </Row>
      <Grid container spacing={2} className="card-row" style={{marginBottom: '30px'}}>
        {cardsData.map((card, index) => (
          <Grid item key={index}>
            {/* Wrap the card content in a Link */}
            <Link to={card.to} style={{textDecoration: 'none'}}>
              <CustomCard name={card.name} icon={<span>{card.icon}</span>} color={card.color}  count={card.count} />
            </Link>
          </Grid>
        ))}
      </Grid>

      <QuadrantLayout>
        <ProcessTrendReport
          time={selectedTimeFilters.value}
          project={selectedProjectFilters.value}
        />
        {isLoading ? loadingIndicator : <LineChart data={taskData} />}
       
      </QuadrantLayout>
      {/* <QRCodeExtractor/> */}
    </>
  );
};

export default Dashboard;
