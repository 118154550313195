import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import "bootstrap/dist/css/bootstrap.min.css";
import "../Styles/StatsTable.css";
import {
  Nav,
  Col,
  Row,
  Button,
  DropdownButton,
  Dropdown,
} from "react-bootstrap";
import GraphStats from "./GraphStats";
import { exportCSV } from "./CustomFunctions";

const StatsTable = ({ data }) => {
  const [graphData, setGraphData] = useState(null);
  const [clickedDatasetLabel, setClickedDatasetLabel] = useState(null);
  const [selectedBlock, setSelectedBlock] = useState(
    data?.[0]?.[0]?.block || ""
  );

  useEffect(() => {
    setGraphData(null);
  }, [data]);

  const handleExportClick = (btntype, tableRows, columns) => {
    //console.log(tableRows);
    const filteredData = tableRows.map((row) => {
      const filteredRow = {};
      columns.forEach((column) => {
        if (typeof column.selector === "function") {
          filteredRow[column.name] = column.selector(row);
        } else {
          filteredRow[column.name] = row[column.selector];
        }
      });
      return filteredRow;
    });
    if (btntype === "graph") {
      setGraphData(filteredData);
    } else if (btntype === "export") {
      exportCSV(filteredData);
    }
  };

  const renderTableAccordionItem = (tableData, index) => {
    if (!Array.isArray(tableData)) {
      console.error(`Table data at index ${index} is not an array`, tableData);
      return <div>Error: Invalid data format</div>;
    }

    let tableRows = [];
    let uniquePorts = new Set();
    let avgStatsKeys = new Set();

    tableData.forEach((item) => {
      if (!Array.isArray(item.data)) {
        return;
      }

      item.data.forEach((task) => {
        const row = {
          task: task.task,
          block: item.block,
          task_name: task.task_name,
          station: task.stations,
          data_dict: task.data_dict,
        };

        let hasPort = false;

        if (Array.isArray(task.records)) {
          task.records.forEach((record) => {
            if (record.port) {
              uniquePorts.add(record.port);
              row[record.port] = record.value_num ?? record.value_text ?? "";
              hasPort = true;
            } else if (
              record.avg_stats &&
              typeof record.avg_stats === "object"
            ) {
              Object.entries(record.avg_stats).forEach(([key, value]) => {
                row[key] = value;
                avgStatsKeys.add(key);
              });
            }
          });
        }

        if (
          !hasPort &&
          Array.isArray(task.records) &&
          task.records.length > 0 &&
          task.records[0].avg_stats &&
          typeof task.records[0].avg_stats === "object"
        ) {
          const avgStats = task.records[0].avg_stats;
          Object.keys(avgStats).forEach((key) => {
            row[key] = avgStats[key];
            avgStatsKeys.add(key);
          });
        }
        tableRows.push(row);
      });
    });

    if (tableRows.length === 0) {
      return <div>No data available for this block</div>;
    }

    let columns = [];

    if (tableRows.some((row) => typeof row.data_dict === "object")) {
      const dataDictKeys = Object.keys(tableRows[0].data_dict || {});

      if (
        dataDictKeys.includes("CSI") &&
        dataDictKeys.includes("Farm") &&
        dataDictKeys.includes("Trial") &&
        dataDictKeys.includes("Year")
      ) {
        columns = [
          {
            name: "CSI",
            selector: (row) => row.data_dict?.CSI,
            sortable: true,
          },
          {
            name: "Farm",
            selector: (row) => row.data_dict?.Farm,
            sortable: true,
          },
          {
            name: "Trial",
            selector: (row) => row.data_dict?.Trial,
            sortable: true,
          },
          {
            name: "Year",
            selector: (row) => row.data_dict?.Year,
            sortable: true,
          },
          {
            name: "Plot",
            selector: (row) => row.data_dict?.Plot,
            sortable: true,
          },
          {
            name: "Lot Number/Line_ID",
            selector: (row) =>
              row.data_dict?.Lot_Number
                ? row.data_dict?.Lot_Number
                : row.data_dict?.Line_ID,
            sortable: true,
          },
          { name: "Block", selector: (row) => row.block, sortable: true },

          ...Array.from(uniquePorts).map((port) => ({
            name: port,
            selector: (row) => row[port],
            sortable: true,
          })),
          ...Array.from(avgStatsKeys).map((key) => ({
            name: key,
            selector: (row) => row[key],
            sortable: true,
          })),
        ];
      } else {
        columns = [
          {
            name: "Item",
            selector: (row) => row.data_dict?.string_identifier,
            sortable: true,
          },
          {
            name: "Task Name",
            selector: (row) => row.task_name,
            sortable: true,
          },
          { name: "Block", selector: (row) => row.block, sortable: true },
          ...Array.from(uniquePorts).map((port) => ({
            name: port,
            selector: (row) => row[port],
            sortable: true,
          })),
          ...Array.from(avgStatsKeys).map((key) => ({
            name: key,
            selector: (row) => row[key],
            sortable: true,
          })),
        ];
      }
    } else {
      columns = [
        { name: "Task Name", selector: (row) => row.task_name, sortable: true },
        { name: "Block", selector: (row) => row.block, sortable: true },
        ...Array.from(uniquePorts).map((port) => ({
          name: port,
          selector: (row) => row[port],
          sortable: true,
        })),
        ...Array.from(avgStatsKeys).map((key) => ({
          name: key,
          selector: (row) => row[key],
          sortable: true,
        })),
      ];
    }

    //console.log(columns);
    //console.log(tableRows);

    return (
      <DataTable
        columns={columns}
        data={tableRows}
        pagination
        highlightOnHover
        subHeader
        subHeaderComponent={
          <>
            <DropdownButton id="export-dropdown" title="Actions">
              <Dropdown.Item
                as={Button}
                onClick={() => handleExportClick("export", tableRows, columns)}
              >
                Export CSV
              </Dropdown.Item>
              <Dropdown.Item
                as={Button}
                onClick={() => handleExportClick("graph", tableRows, columns)}
              >
                Graph It
              </Dropdown.Item>
            </DropdownButton>
            {/* <Button onClick={() => handleExportClick("export", tableRows, columns)}>
              Export CSV
            </Button>
            <Button onClick={() => handleExportClick("graph", tableRows, columns)}>
              View Graph
            </Button> */}
          </>
        }
        subHeaderAlign="right"
      />
    );
  };

  if (!data || data.length === 0) {
    return <div>No data available</div>;
  }

  return (
    <div className="stats-table">
      <Row style={{ backgroundColor: "white" }}>
        <Col md={12}>
          <Nav
            justify
            fill
            variant="tabs"
            activeKey={selectedBlock}
            onSelect={(key) => setSelectedBlock(key)}
          >
            {data.map((tableData, index) => (
              <Nav.Item key={index} style={{ color: "red" }}>
                <Nav.Link
                  eventKey={tableData?.[0]?.block || `block-${index}`}
                  style={{ color: "gray", fontWeight: "bold" }}
                >
                  {tableData?.[0]?.block || `Block ${index}`}
                </Nav.Link>
              </Nav.Item>
            ))}
          </Nav>
          <div style={{ marginTop: "20px" }}>
            {data.map((tableData, index) =>
              tableData?.[0]?.block === selectedBlock
                ? renderTableAccordionItem(tableData, index)
                : null
            )}
          </div>
        </Col>
      </Row>
      {graphData != null && (
        <GraphStats data={graphData} onDatasetClick={setClickedDatasetLabel} />
      )}
    </div>
  );
};

export default StatsTable;
