import React from 'react';
import styled from 'styled-components';

// Styled components
const Container = styled.div`
  width: 100%;
  margin: 0 auto;
  padding: 20px;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  color: #333;
  line-height: 1.6;
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;

const Title = styled.h1`
  text-align: center;
  font-size: 2.5rem;
  color: #004d7a;
  margin-bottom: 20px;
`;

const Section = styled.div`
  margin-bottom: 30px;
`;

const SectionTitle = styled.h2`
  font-size: 1.8rem;
  color: #006494;
  margin-bottom: 15px;
`;

const Subsection = styled.div`
  margin-left: 20px;
`;

const SubsectionTitle = styled.h3`
  font-size: 1.5rem;
  color: #008cba;
  margin-bottom: 10px;
`;

const Paragraph = styled.p`
  font-size: 1rem;
  color: #555;
  margin-bottom: 10px;
`;

const List = styled.ul`
  margin-left: 20px;
  list-style-type: disc;
`;

const ListItem = styled.li`
  margin-bottom: 5px;
`;

// React Component
const LimsNexusDescription = () => {
  return (
    <Container>
      <Title>LIMS Nexus: A Comprehensive Laboratory Management Solution</Title>
      
      <Section>
        <SectionTitle>Overview</SectionTitle>
        <Paragraph>
          LIMS Nexus is a versatile laboratory management application designed to cater to the dynamic needs of laboratories. With both iOS and web app versions, it offers a robust platform for data collection, analysis, and management. The iOS app focuses on real-time data collection, while the web app provides a comprehensive interface for viewing, exporting, and managing data, making it ideal for both technical and managerial use cases. LIMS Nexus empowers users to configure their lab environment according to their specific requirements, providing unparalleled flexibility and control over laboratory processes.
        </Paragraph>
      </Section>

      <Section>
        <SectionTitle>Key Features and Functionality</SectionTitle>
        
        <Subsection>
          <SubsectionTitle>1. Dynamic Setup</SubsectionTitle>
          <Paragraph>
            LIMS Nexus allows users to dynamically configure the app to match their laboratory workflows. This setup includes defining working environments, equipment, data inputs, and calculation protocols, ensuring that the app adapts seamlessly to any lab's unique needs.
          </Paragraph>
        </Subsection>

        <Subsection>
          <SubsectionTitle>2. Stations</SubsectionTitle>
          <Paragraph><strong>Definition:</strong> Stations in LIMS Nexus represent working environments within the laboratory. Each station can be tailored to fit specific functions, such as a seed analysis area or an image processing station.</Paragraph>
          <Paragraph><strong>Functionality:</strong> Users can create and manage multiple stations, each designed to handle different types of data or tasks, facilitating a structured and organized workflow.</Paragraph>
        </Subsection>

        <Subsection>
          <SubsectionTitle>3. Blocks</SubsectionTitle>
          <Paragraph><strong>Definition:</strong> Blocks represent the machines or equipment used within a station. These could be physical devices like scales for measuring seed weight or software tools for image analysis.</Paragraph>
          <Paragraph><strong>Functionality:</strong> Blocks are the core components of a station. Users can define the types of data each block will handle and configure them to operate in tandem with other blocks, ensuring seamless data flow and process efficiency.</Paragraph>
        </Subsection>

        <Subsection>
          <SubsectionTitle>4. Ports</SubsectionTitle>
          <Paragraph><strong>Definition:</strong> Ports are the input fields associated with blocks. They represent the specific data points or measurements that need to be collected, such as weight, color, length, or area.</Paragraph>
          <Paragraph><strong>Functionality:</strong> Ports can be customized to handle various data types, including numeric, text, or predefined choices. This flexibility allows users to capture all relevant data accurately and efficiently.</Paragraph>
        </Subsection>

        <Subsection>
          <SubsectionTitle>5. Units</SubsectionTitle>
          <Paragraph><strong>Definition:</strong> Units represent the measurements used within ports. Examples include grams, centimeters, or any other standard unit relevant to the data being collected.</Paragraph>
          <Paragraph><strong>Functionality:</strong> Users can define and apply units to ensure that all data is consistent and comparable across different blocks and stations.</Paragraph>
        </Subsection>

        <Subsection>
          <SubsectionTitle>6. Protocols</SubsectionTitle>
          <Paragraph><strong>Definition:</strong> Protocols are collections of blocks grouped together to form a standardized procedure or workflow. A protocol might include a series of measurements and analyses that must be conducted in a specific order.</Paragraph>
          <Paragraph><strong>Functionality:</strong> Protocols streamline laboratory processes by providing a predefined path for data collection and analysis, ensuring consistency and repeatability.</Paragraph>
        </Subsection>

        <Subsection>
          <SubsectionTitle>7. Formulas</SubsectionTitle>
          <Paragraph><strong>Definition:</strong> Formulas are calculation tools that apply specific mathematical operations to data collected in ports. They help derive meaningful metrics from raw data, such as converting measurements into a standard format or calculating averages.</Paragraph>
          <Paragraph><strong>Functionality:</strong> Users can create custom formulas to perform calculations on the data captured by blocks, enhancing the analytical capabilities of the app.</Paragraph>
        </Subsection>
      </Section>

      <Section>
        <SectionTitle>Power and Flexibility</SectionTitle>
        <Paragraph>
          LIMS Nexus stands out for its ability to adapt to the diverse and evolving needs of laboratory environments. By allowing users to define and manage every aspect of their workflow—from the creation of stations to the application of complex protocols and formulas—the app provides a powerful tool for improving efficiency, accuracy, and data management in the lab. Whether it's collecting seed weight data or performing intricate image analysis, LIMS Nexus is designed to handle it all, making it an indispensable asset for modern laboratories.
        </Paragraph>
      </Section>
    </Container>
  );
};

export default LimsNexusDescription;
