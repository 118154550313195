import React, { useEffect, useState, useRef } from "react";
import apiCalls from "../Services/AxiosCalls";
import ImageCard from "../Components /ImageCard";
import Pagination from "../Components /Pagination";
import {
  Row,
  Col,
  Form,
  Spinner,
  Button,
  Stack,
  Offcanvas,
  DropdownButton,
  Dropdown,
} from "react-bootstrap";
import Select from "react-select";
import ImageView from "../Components /ImageView";
import InteractiveTable from "../Components /InteractiveTable";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter } from "@fortawesome/free-solid-svg-icons";
import NotificationContent from "../Components /NotificationContent";
import flashMsg from "../Components /Notification";
import { exportImagesToZip } from "../Components /exportImagesToZip";
import PDFReport from "../Components /PDFReport";
import { PDFDownloadLink, pdf } from "@react-pdf/renderer";
import { saveAs } from "file-saver"; // For file download

const Images = () => {
  const [imageData, setImageData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [loading, setLoading] = useState(true);
  const [isImageLoading, setIsImageLoading] = useState(false); // State to track loading status

  const [allTasks, setAllTasks] = useState(null);
  const [itemsOptions, setItemsOptions] = useState(null);
  const [filteresData, setFilteresData] = useState(null);
  const [showFilter, setShowFilter] = useState(false);
  const [params, setParams] = useState({});
  const [submitLoading, setSubmitLoading] = useState(false);
  const [showDownloadLink, setShowDownloadLink] = useState(false);
  const [filterText, setFilterText] = useState("");

  const ref = useRef();

  useEffect(() => {
    getImages();
  }, [
    // imageData
    // params
    currentPage,
  ]);

  useEffect(() => {
    // getProjects();
    getImages();
    getExtractedFilteres();
  }, []);

  const toggleFilter = () => {
    setShowFilter(!showFilter);
  };

  const getExtractedFilteres = async () => {
    try {
      const response = await apiCalls.ExtractFilteres();
      //console.log(response.data);
      setFilteresData(response.data);
      // setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const getProjects = async () => {
    try {
      const [projectResponse, itemResponse] = await Promise.all([
        apiCalls.getProject(),
        apiCalls.getAllItems(),
      ]);

      if (
        !projectResponse ||
        projectResponse.status !== 200 ||
        !itemResponse ||
        itemResponse.status !== 200
      ) {
        throw new Error("Error fetching projects or items.");
      }

      const projectData = projectResponse.data;
      const itemData = itemResponse.data;

      const filterOptions = itemData.map((item) => ({
        value: item.id,
        label: item.name,
      }));

      const taskOptions = projectData.map((data) => ({
        label: data.name,
        value: data.id,
      }));

      setAllTasks(taskOptions);
      setItemsOptions(filterOptions);
    } catch (error) {
      console.error("Error during project and item retrieval:", error.message);
    }
  };

  const getImages = async () => {
    //console.log(params);
    try {
      const BL_params = {
        project_id: params.project_id?.value,
        crop: params.crop?.value,
        csi: params.csi?.value,
        farm: params.farm?.value,
        trial: params.trial?.value,
        year: params.year?.value,
        location: params.location?.value,
        task_id: params.task_id?.value,
        // page: currentPage,
      };
      //console.log(BL_params);

      const response = await apiCalls.getImageHistory(BL_params);
      //console.log(response);

      if (response && response.data) {
        const { data } = response;
        const objectsWithFalseAvgStats = data.filter(
          (obj) => !obj.has_avg_stats_out
        );
        // if (objectsWithFalseAvgStats.length > 0) {
        //   flashMsg.imageError(
        //     "These items should be re-imaged",
        //     <NotificationContent data={objectsWithFalseAvgStats} />,
        //     "warning"
        //   );
        // }
        //console.log(objectsWithFalseAvgStats);
        // //console.log(data[0].image_data);

        if (data.length > 0) {
          // const newData = data.slice(0, 40);
          setImageData(data);
          setLoading(false);

          // setTotalPages(data.num_pages);
          // setShowNext(data.next);
          // setShowPrev(data.previous);
        } else {
          console.error("Invalid API response structure:", response);
          setTotalPages(0);
          setImageData([]);
        }
      } else {
        console.error("Invalid API response:", response);
        setTotalPages(0);
        setImageData([]);
      }

      setLoading(false);
    } catch (error) {
      console.error("Error fetching images:", error.message);
      setLoading(false);
      setTotalPages(0);
      setImageData([]);
    }
  };

  const handlePageChange = (newPage) => {
    //console.log(newPage);
    // setParams({ ...params, page: newPage })
    setCurrentPage(newPage);
  };

  const renderLoadingSpinner = () => {
    return (
      <div className="text-center my-3">
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div>
    );
  };
  const handleExportImages = async (data) => {
    setIsImageLoading(true);

    // Fetch or prepare the data you want to export
    const dataToExport = data; // Your data here

    // Call the exportImagesToZip function
    await exportImagesToZip(dataToExport);
    setIsImageLoading(false);
  };

  const handleFilter = (e) => {
    const text = e.target.value;
    setFilterText(text);
  };

  const handleExportCSV = () => {
    // Implement CSV export logic
    ref.current.downloadCSV();
    //console.log(ref.current);
  };

  const handleDownload = () => {
    // Implement download logic
    ref.current.downloadImages();

    //console.log("Download");
  };

  const handleExportPDF = () => {
    // Implement PDF export logic
    ref.current.generatePDF();
    //console.log("Export PDF");
  };

  return (
    <div className="image-card">
      <Row className="align-items-center">
        {" "}
        {/* Ensure aligned content */}
        <Col xs={3}>
          <Stack direction="horizontal" gap={3} style={{ marginBottom: "6px" }}>
            <Button
              className="p-2"
              style={{ backgroundColor: "#4E73DF" }}
              onClick={toggleFilter}
            >
              <FontAwesomeIcon icon={faFilter} /> Filters
            </Button>

            <div className="vr" />
            {!loading && (
              <>
                <DropdownButton id="export-dropdown" title="Actions">
                  <Dropdown.Item as={Button} onClick={handleExportCSV}>
                    Export CSV
                  </Dropdown.Item>
                  <Dropdown.Item as={Button} onClick={handleDownload}>
                    Download Images
                  </Dropdown.Item>
                  <Dropdown.Item as={Button} onClick={handleExportPDF}>
                    Export PDF
                  </Dropdown.Item>
                </DropdownButton>
              </>
            )}
          </Stack>
        </Col>
        <Col xs={9} className="d-flex justify-content-end">
          {" "}
          {/* Ensure right-alignment */}
          <span
            className="bi bi-search"
            style={{
              position: "absolute",
              top: "50%",
              left: "15px",
              transform: "translateY(-50%)",
              fontSize: "1.2rem",
              color: "#6c757d",
            }}
          ></span>
          <Form.Control
            type="text"
            placeholder="Search..."
            value={filterText}
            onChange={handleFilter}
            className="mb-2 beautiful-search-field" // Add custom class for styling
            style={{
              width: "calc(40% - 30px)",
              paddingLeft: "30px",
              borderRadius: "20px",
            }} // Adjust width and padding
          />{" "}
          {/* Include custom styling class */}
        </Col>
      </Row>

      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      ) : (
        <>
          <InteractiveTable
            data={imageData}
            filterData={filterText}
            exportData={handleExportImages}
            ref={ref}
          />

          {filteresData !== null && (
            <Offcanvas show={showFilter} onHide={toggleFilter}>
              <Offcanvas.Header closeButton>
                <Offcanvas.Title>Filters</Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body style={{ overflow: "inherit" }}>
                <Row>
                  <Col xs={12} style={{ marginBottom: "5px" }}>
                    {/* Select for Project */}
                    <Select
                      options={filteresData.Project.map((item) => ({
                        value: item[0],
                        label: item[1],
                      }))}
                      placeholder="Project"
                      onChange={(selectedOptions) =>
                        setParams({ ...params, project_id: selectedOptions })
                      }
                      value={params.project_id || null}
                      isClearable={true}
                    />
                  </Col>
                  <Col xs={12} style={{ marginBottom: "7px" }}>
                    {/* Select for Tasks */}
                    <Select
                      options={filteresData.Task.map((item) => ({
                        value: item[0],
                        label: item[1],
                      }))}
                      placeholder="Tasks"
                      onChange={(selectedOptions) =>
                        setParams({ ...params, task_id: selectedOptions })
                      }
                      value={params.task_id || null}
                      isClearable={true}
                    />
                  </Col>
                  <Col xs={12} style={{ marginBottom: "5px" }}>
                    {/* Select for Crop */}
                    <Select
                      options={filteresData.Crop.map((item) => ({
                        value: item[0],
                        label: item[1],
                      }))}
                      placeholder="Crop"
                      onChange={(selectedOptions) =>
                        setParams({ ...params, crop: selectedOptions })
                      }
                      value={params.crop || null}
                      isClearable={true}
                    />
                  </Col>
                  <Col xs={12} style={{ marginBottom: "5px" }}>
                    {/* Select for Farm */}
                    <Select
                      options={filteresData.Farm.map((item) => ({
                        value: item[0],
                        label: item[1],
                      }))}
                      placeholder="Farm"
                      onChange={(selectedOptions) =>
                        setParams({ ...params, farm: selectedOptions })
                      }
                      value={params.farm || null}
                      isClearable={true}
                    />
                  </Col>
                  <Col xs={12} style={{ marginBottom: "5px" }}>
                    {/* Select for CSI */}
                    <Select
                      options={filteresData.CSI.map((item) => ({
                        value: item[0],
                        label: item[1],
                      }))}
                      placeholder="CSI"
                      onChange={(selectedOptions) =>
                        setParams({ ...params, csi: selectedOptions })
                      }
                      value={params.csi || null}
                      isClearable={true}
                    />
                  </Col>
                  <Col xs={12} style={{ marginBottom: "5px" }}>
                    {/* Select for Trial */}
                    <Select
                      options={filteresData.Trial.map((item) => ({
                        value: item[0],
                        label: item[1],
                      }))}
                      placeholder="Trial"
                      onChange={(selectedOptions) =>
                        setParams({ ...params, trial: selectedOptions })
                      }
                      value={params.trial || null}
                      isClearable={true}
                    />
                  </Col>
                  <Col xs={12} style={{ marginBottom: "5px" }}>
                    {/* Select for Year */}
                    <Select
                      options={filteresData.Year.map((item) => ({
                        value: item[0],
                        label: item[1],
                      }))}
                      placeholder="Year"
                      onChange={(selectedOptions) =>
                        setParams({ ...params, year: selectedOptions })
                      }
                      value={params.year || null}
                      isClearable={true}
                    />
                  </Col>
                  <Col xs={12} style={{ marginBottom: "5px" }}>
                    {/* Select for Year */}
                    <Select
                      options={filteresData.Location.map((item) => ({
                        value: item[0],
                        label: item[1],
                      }))}
                      placeholder="Location"
                      onChange={(selectedOptions) =>
                        setParams({ ...params, location: selectedOptions })
                      }
                      value={params.location || null}
                      isClearable={true}
                    />
                  </Col>
                </Row>
                <Row>
                  <Stack
                    direction="horizontal"
                    gap={3}
                    style={{
                      marginBottom: "5px",
                      display: "flex",
                      justifyContent: "flex-end",
                    }}
                  >
                    <Button
                      variant="secondary"
                      onClick={async () => {
                        setSubmitLoading(true); // Set loading state to true when submitting
                        await getImages(); // Call submit function
                        setSubmitLoading(false);
                        toggleFilter(); // Set loading state to false when done
                      }}
                      disabled={submitLoading} // Disable button when loading
                    >
                      {submitLoading ? "Fetching Data..." : "Fetch"}
                    </Button>
                    <div className="vr" />
                    <Button variant="outline-danger" onClick={toggleFilter}>
                      Cancel
                    </Button>
                  </Stack>
                </Row>
              </Offcanvas.Body>
            </Offcanvas>
          )}
        </>
      )}
      {submitLoading && renderLoadingSpinner()}
    </div>
  );
};

export default Images;
