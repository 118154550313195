// import { useContext } from "react";
// import { AxiosContext } from "./AxiosContext";
import axios from "axios";
import { jwtDecode } from "jwt-decode";
import Cookies from "js-cookie";
// import { useCookies } from "react-cookie";

// let jwt;
let baseURL = "";
let cookie;

if (window.location.origin === "http://localhost:8009") {

  baseURL = "http://0.0.0.0:8008/";
} else {
  baseURL = `${window.location.origin}/`;
}


// axios.defaults.xsrfCookieName = 'csrftoken';
// axios.defaults.xsrfHeaderName = 'X-CSRFToken';

const authAxios = axios.create({
  baseURL,
  withCredentials: true,
  xsrfCookieName: "csrftoken",
  xsrfHeaderName: "X-CSRFToken",
});

// Interceptor for request handling
authAxios.interceptors.request.use(
  async (config) => {
    const storedTokens = await localStorage.getItem("authTokens");

    if (storedTokens) {
      const { accessToken, refreshToken } = JSON.parse(storedTokens);
      const decodedToken = jwtDecode(accessToken);

      const isTokenExpired = (token) => decodedToken.exp < Date.now() / 1000;

      if (isTokenExpired(accessToken)) {
        let apiUrl;
        if (window.location.origin.includes("localhost")) {
          apiUrl = "https://www.caessia.com/api/token/refresh/";
        } else if (window.location.origin.includes("lims.caessia.com")) {
          apiUrl = "https://login.caessia.com/api/token/refresh/";
        } else {
          apiUrl = "https://login.seednexus.com/api/token/refresh/";
        }

        try {
          const response = await axios.post(apiUrl, { refresh: refreshToken });
          const newAccessToken = response.data;
          // //console.log(newAccessToken);

          localStorage.setItem(
            "authTokens",
            JSON.stringify({
              accessToken: newAccessToken.access,
              refreshToken: newAccessToken.refresh,
            })
          );

          config.headers["Authorization"] = `Bearer ${newAccessToken.access}`;
        } catch (error) {
          console.error("Failed to refresh token:", error);
          localStorage.setItem("tokenExpired", "true");
          throw error; // This will be caught in the response interceptor and trigger logout
        }
      } else {
        config.headers["Authorization"] = `Bearer ${accessToken}`;
      }
    }

    return config;
  },
  (error) => Promise.reject(error)
);

// Interceptor for response handling
authAxios.interceptors.response.use(
  async (response) => {
    return handleResponse(response);
  },
  async (error) => {
    const originalRequest = error.config;

    if (!originalRequest._retry) {
      originalRequest._retry = 0;
    }

    if (error.response && error.response.status === 401) {
      if (originalRequest._retry < 3) {
        originalRequest._retry += 1;
        console.error("Unauthorized, retrying request...", error.response.status);

        try {
          const storedTokens = await localStorage.getItem("authTokens");
          const { refreshToken } = JSON.parse(storedTokens);

          let apiUrl;
          if (window.location.origin.includes("localhost")) {
            apiUrl = "https://www.caessia.com/api/token/refresh/";
          } else if (window.location.origin.includes("lims.caessia.com")) {
            apiUrl = "https://login.caessia.com/api/token/refresh/";
          } else {
            apiUrl = "https://login.seednexus.com/api/token/refresh/";
          }

          const response = await axios.post(apiUrl, { refresh: refreshToken });
          const newAccessToken = response.data;

          localStorage.setItem(
            "authTokens",
            JSON.stringify({
              accessToken: newAccessToken.access,
              refreshToken: newAccessToken.refresh,
            })
          );

          originalRequest.headers["Authorization"] = `Bearer ${newAccessToken.access}`;
          return authAxios(originalRequest); // Retry the original request with the new token
        } catch (refreshError) {
          console.error("Failed to refresh token:", refreshError);
          localStorage.setItem("tokenExpired", "true");
          // Handle logout, e.g., redirect to login
          // window.location.href = "/login";
        }
      } else {
        console.error("Unauthorized, logging out...");
        localStorage.setItem("tokenExpired", "true");
        // Handle logout, e.g., redirect to login
        // window.location.href = "/login";
      }
    } else if (error.response && [403, 502].includes(error.response.status)) {
      // Handle other specific status codes like 403 or 502
      // Retry logic for these errors
      if (originalRequest._retry < 3) {
        originalRequest._retry += 1;
        return authAxios(originalRequest);
      } else {
        console.error("Retry limit reached:", error);
      }
    } else {
      console.error("An error occurred:", error);
    }

    return Promise.reject(error);
  }
);

async function handleResponse(response) {
  const csrfToken =
    response.headers["x-csrftoken"] || response.headers["X-CSRFToken"];
  cookie = csrfToken;

  if (csrfToken) {
    Cookies.set("csrftoken", csrfToken, {
      path: "/",
      sameSite: "None",
      secure: true,
    });
  }

  return response;
}



//GET Calls=============================================================  =================================================
const getAllUnits = () => {
  return authAxios.get("api-main/unit-standard/");
};
const getCommonUnit = () => {
  return authAxios.get("api-main/unit-common/");
};

const getUser = () => {
  return authAxios.get("api-users/user/me/");
};
const getAllItems = () => {
  return authAxios.get("api-main/item/");
};
const getAllStations = () => {
  return authAxios.get("api-main/station/");
};
const getAllMachines = () => {
  return authAxios.get("api-main/block/");
};
const getAllMachinesStats = () => {
  return authAxios.get("api-main/block-stats/");
};
const getAllFormulas = () => {
  return authAxios.get("api-main/formula/");
};
const getFormulaByID = (data) => {
  return authAxios.get("api-main/formula/" + data + "/");
};
const getStationMachine = (data) => {
  return authAxios.get("api-main/station/" + data + "/block/");
};
const getTrial = (data) => {
  return authAxios.get("api-main/trial/");
};
const getIteration = () => {
  return authAxios.get("api-main/iteration/");
};
const getItemIteration = (data) => {
  return authAxios.get("api-main/item/" + data + "/iteration/");
};
const getItemIterationTask = (data) => {
  return authAxios.get("api-projects/item/" + data + "/task/");
};
const getItemITask = (data) => {
  return authAxios.get("api-projects/item/" + data + "/task/");
};
const getPreviousData = (itID, blID) => {
  return authAxios.get(
    "api-main/iteration/" + itID + "/block/" + blID + "/process/"
  );
};
const getPreviousRecord = (data) => {
  return authAxios.get("api-main/process/" + data + "/record/");
};
const getPreviousImageRecord = (itrration, block) => {
  return authAxios.get(
    "api-main/iteration/" +
      itrration +
      "/block/" +
      block +
      "/imaging-record-set/"
  );
};
const getblockFormular = (data) => {
  return authAxios.get("api-main/block/" + data + "/formula/");
};
const getblockPorts = (data) => {
  return authAxios.get("api-main/block/" + data + "/port/");
};
const getPortsByID = (data) => {
  return authAxios.get("api-main/port/" + data + "/");
};
const getBlockByID = (id) => {
  return authAxios.get("api-main/block/" + id + "/");
};

const getTemplate = () => {
  return authAxios.get("api-main/record-batch-template/1/?columns=1&rows=1", {
    responseType: "blob",
  });
};
const getOrganization = (id) => {
  return authAxios.get("api-users/department/" + id + "/");
};
const getAllUSers = (id) => {
  return authAxios.get("api-users/user/");
};
const getAllDepartmentUSers = () => {
  return authAxios.get("api-users/users/get/");
};
const updateDepartmentUser = (data) => {
  return authAxios.put("api-users/users/put/", data);
};

const getHistory = () => {
  return authAxios.get("api-main/process-history/");
};
const getAllRecordHistory = async (page, queryParams) => {
  try {
    const response = await authAxios.get(
      `api-main/allrecord-history/?page=${page}`,
      {
        params: queryParams,
      }
    );
    // //console.log(response);
    return response;
  } catch (error) {
    throw error;
  }
};
const getImageHistory = async (params) => {
  try {
    return authAxios.get(`api-main/image-history/`, { params });
  } catch (error) {
    throw error;
  }
};

const getRecordSeries = () => {
  return authAxios.get("api-main/records-time-series/");
};
const getRecordAnalysis = (data) => {
  return authAxios.get("api-main/record-analysis/?time_interval=" + data);
};
const getProcessPerBlock = (params) => {
  return authAxios.get("api-main/processes-per-block-trend/", { params });
};
const getRecordsPerCount = (data) => {
  return authAxios.get(
    "api-main/records-per-count-trend/?time_interval=" + data
  );
};

// POST Calls
const saveStation = (data) => {
  return authAxios.post("api-main/station/", data);
};
const saveMachine = (data) => {
  return authAxios.post("api-main/block/", data);
};
const saveFormula = (data) => {
  return authAxios.post("api-main/formula/", data);
};
const saveTrial = (data) => {
  return authAxios.post("api-main/trial/", data);
};
const saveSeed = (data) => {
  return authAxios.post("api-main/item/", data);
};
const saveIteration = (data) => {
  return authAxios.post("api-main/iteration/", data);
};
const saveProcess = (data) => {
  return authAxios.post("api-main/process/", data);
};
const saveRecord = (data) => {
  return authAxios.post("api-main/record/", data);
};
const saveImageRecord = (data) => {
  return authAxios.post("api-main/imaging/", data, { timeout: 600000 });
};
const imageFeedBack = (id, data) => {
  return authAxios.patch("api-main/imaging/" + id + "/", data);
};
const applyFormula = (data) => {
  return authAxios.post("api-main/formula-evaluation/", data);
};

const savePorts = (data) => {
  return authAxios.post("api-main/port/", data);
};
const saveBatchRecord = (data) => {
  return authAxios.post("api-main/record-batch/", data);
};

const postUser = (data) => {
  return authAxios.post("api-users/user/", data);
};

const getPDF = (data) => {
  return authAxios.post("api-main/generate-report/", data);
};

//PUT Requests
const updateStation = (id, data) => {
  return authAxios.put("api-main/station/" + id + "/", data);
};
const updateFormula = (id, data) => {
  return authAxios.put("api-main/formula/" + id + "/", data);
};
const updateRecord = (id, data) => {
  return authAxios.put("api-main/record/" + id + "/", data);
};
const updateIteration = (id, data) => {
  return authAxios.put("api-main/iteration/" + id + "/", data);
};

const updateBlock = (id, data) => {
  return authAxios.put("api-main/block/" + id + "/", data);
};

const updatePort = (id, data) => {
  return authAxios.put("api-main/port/" + id + "/", data);
};

//DELETE Requests
const deleteStation = (id) => {
  return authAxios.delete("api-main/station/" + id + "/");
};
const deleteFormula = (id) => {
  return authAxios.delete("api-main/formula/" + id + "/");
};
const deleteProcess = (id) => {
  return authAxios.delete("api-main/process/" + id + "/");
};
const deleteBlock = (id) => {
  return authAxios.delete("api-main/block/" + id + "/");
};
const deleteTask = (id) => {
  return authAxios.delete("api-projects/task/" + id + "/delete/");
};

//PROJECTS
const postProject = (data) => {
  return authAxios.post("api-projects/project/", data);
};
const postClient = (data) => {
  return authAxios.post("api-projects/client/", data);
};
const postCrop = (data) => {
  return authAxios.post("api-projects/crop/", data);
};
const postTask = (data) => {
  return authAxios.post("api-projects/task/", data);
};
const updateTask = (id, data) => {
  return authAxios.put("api-projects/task/" + id + "/", data);
};
const updateProtocol = (id, data) => {
  return authAxios.put("api-projects/protocol/" + id + "/", data);
};
const updateProject = (id, data) => {
  return authAxios.put("api-projects/project/" + id + "/", data);
};
const updateCrop = (id, data) => {
  return authAxios.put("api-projects/crop/" + id + "/", data);
};
const updateClient = (id, data) => {
  return authAxios.put("api-projects/client/" + id + "/", data);
};

// const getItemIteration = (id) => {
//   return authAxios.put("api-projects/item/" + id + "task/");
// };

const getCrop = () => {
  return authAxios.get("api-projects/crop/");
};

const getClient = () => {
  return authAxios.get("api-projects/client/");
};

const getProject = () => {
  return authAxios.get("api-projects/project/");
};

const postProtocol = (data) => {
  return authAxios.post("api-projects/protocol/", data);
};
const getProtocol = () => {
  return authAxios.get("api-projects/protocol/");
};
const getTask = () => {
  return authAxios.get("api-projects/task-assignee/");
};
const getAllTask = () => {
  return authAxios.get("api-projects/task/");
};
const getItemID = (id) => {
  return authAxios.get("api-main/item/" + id + "/");
};

const getProtocolID = (id) => {
  return authAxios.get("api-projects/protocol/" + id + "/");
};

const getTaskByID = (id) => {
  return authAxios.get("api-projects/task/" + id + "/");
};
const getProjectTasks = (id) => {
  return authAxios.get("api-projects/project/" + id + "/task/");
};
const getPRecordsaByProjects = (id) => {
  return authAxios.get("api-main/project-records/" + id + "/");
};

const getProjectTaskCompleteReport = (params) => {
  return authAxios.get(`api-projects/task-complete-trend/`, { params });
};

const getImageStats = (params) => {
  return authAxios.get(`api-main/image-record-report/`, { params });
};
const getTaskTrend = (params) => {
  return authAxios.get(`api-projects/task-trend-report/`, { params });
};
const seedImageReport = (params) => {
  return authAxios.get(`api-main/image-data-report/`, { params });
};
const FilteredeReport = (params) => {
  return authAxios.get(`api-main/stats-report/`, { params });
};
const FilteredImageReport = (params) => {
  return authAxios.get(`api-main/images-stats-report/`, { params });
};
const ExtractFilteres = () => {
  return authAxios.get(`api-main/extract_data/`);
};

//////INVENTORY ENDPOINTS
const postStorageFacility = (data) => {
  return authAxios.post(`api-inventory/storage_facilities/`, data);
};
const postRack = (data) => {
  return authAxios.post(`api-inventory/rack/`, data);
};
const postShelf = (data) => {
  return authAxios.post(`api-inventory/shelf/`, data);
};

const postBox = (data) => {
  return authAxios.post(`api-inventory/box/`, data);
};
const postInventory = (data) => {
  return authAxios.post(`api-inventory/inventory/`, data);
};
const postInventoryTransaction = (data) => {
  return authAxios.post(`api-inventory/inventory-transactions/`, data);
};

const generateQRCode = (data) => {
  return authAxios.post(`api-inventory/generate-qr-code/`, data);
};
const generateQRPDF = (data) => {
  return authAxios.post(`api-inventory/generate-qr-pdf/`, data, {
    responseType: "blob", // Set responseType to 'blob' for binary data (e.g., PDF)
  });
};

//PUT
const updateFacility = (id, data) => {
  return authAxios.put("api-inventory/storage_facilities/" + id + "/", data);
};
const updateRack = (id, data) => {
  return authAxios.put("api-inventory/rack/" + id + "/", data);
};
const updateBox = (id, data) => {
  return authAxios.put("api-inventory/box/" + id + "/", data);
};
const updateShelve = (id, data) => {
  return authAxios.put("api-inventory/shelf/" + id + "/", data);
};
const updateInventoryTransaction = (id, data) => {
  return authAxios.put(
    "api-inventory/inventory-transactions/" + id + "/",
    data
  );
};
const updateInventory = (inventoryId, data) => {
  return authAxios.put(`api-inventory/inventory/?id=${inventoryId}`, data);
};

///GET
const getShelves = () => {
  return authAxios.get(`api-inventory/shelf/`);
};
const getStorageFacility = () => {
  return authAxios.get(`api-inventory/storage_facilities/`);
};
const getBoxes = () => {
  return authAxios.get(`api-inventory/box/`);
};
const getRacks = () => {
  return authAxios.get(`api-inventory/rack/`);
};
const getInventory = () => {
  return authAxios.get(`api-inventory/inventory/`);
};
const getInventoryTransaction = () => {
  return authAxios.get(`api-inventory/inventory-transactions/`);
};
const getInventoryTransactionbyInventoryID = (id) => {
  return authAxios.get("api-inventory/inventory_transactions/" + id + "/");
};
const InventoryFIlters = (params) => {
  return authAxios.get("api-inventory/filter-inventory/", { params });
};

//DELETE
const deleteFacility = (id) => {
  return authAxios.delete("api-inventory/storage_facilities/" + id + "/");
};
const deleteRack = (id) => {
  return authAxios.delete("api-inventory/rack/" + id + "/");
};
const deleteBox = (id) => {
  return authAxios.delete("api-inventory/box/" + id + "/");
};
const deleteShelve = (id) => {
  return authAxios.delete("api-inventory/shelf/" + id + "/");
};
const deleteInventoryTransaction = (id) => {
  return authAxios.delete("api-inventory/inventory-transactions/" + id + "/");
};

const deleteInventory = (inventoryId) => {
  return authAxios.delete(`api-inventory/inventory/?id=${inventoryId}`);
};

/// USER VIRTUAL MODE
const virtualModeDepartments = (data) => {
  return authAxios.get("api-users/user-organizations/");
};
const virtualModeActivate = (data) => {
  //console.log(data);
  return authAxios.post(
    "api-users/user-virtual-mode/toggle-virtual-mode/",
    data
  );
};

const getProcessID = (block, iteration) => {
  return authAxios.get(
    "api-main/im-process/" + block + "/block/" + iteration + "/"
  );
};
const getDashCounts = () => {
  return authAxios.get("api-main/model-counts/");
};
const apiCalls = {
  //project
  // getItemIteration,
  //INventory
  getDashCounts,
  getProcessID,
  virtualModeActivate,
  virtualModeDepartments,
  updateDepartmentUser,
  getAllDepartmentUSers,
  generateQRPDF,
  generateQRCode,
  InventoryFIlters,
  updateInventory,
  deleteInventory,
  getInventory,
  getInventoryTransaction,
  updateInventoryTransaction,
  postInventoryTransaction,
  postInventory,
  deleteInventoryTransaction,
  deleteBox,
  deleteFacility,
  deleteRack,
  deleteShelve,
  updateShelve,
  updateBox,
  updateRack,
  updateFacility,
  getBoxes,
  getShelves,
  postStorageFacility,
  postShelf,
  postBox,
  postRack,
  getStorageFacility,
  getRacks,
  getInventoryTransactionbyInventoryID,
  ExtractFilteres,
  FilteredImageReport,
  FilteredeReport,
  seedImageReport,
  getTaskTrend,
  getImageStats,
  getProjectTaskCompleteReport,
  getRecordsPerCount,
  getAllRecordHistory,
  getImageHistory,
  postUser,
  getTaskByID,
  getAllTask,
  getItemITask,
  updateClient,
  updateCrop,
  getProjectTasks,
  updateTask,
  updateProject,
  updateProtocol,
  postProject,
  postProtocol,
  getProtocol,
  getProject,
  postClient,
  postCrop,
  getClient,
  getCrop,
  postTask,
  getTask,
  getItemID,
  getProtocolID,
  getRecordSeries,
  getItemIterationTask,
  getUser,
  getAllUSers,
  updatePort,
  deleteProcess,
  getPreviousImageRecord,
  applyFormula,
  getAllUnits,
  saveStation,
  getAllStations,
  saveMachine,
  deleteBlock,
  getAllMachines,
  updateStation,
  deleteStation,
  saveFormula,
  getAllFormulas,
  updateFormula,
  deleteFormula,
  getStationMachine,
  saveTrial,
  getTrial,
  saveSeed,
  saveIteration,
  getIteration,
  getItemIteration,
  saveProcess,
  getPreviousData,
  saveRecord,
  getAllItems,
  getPreviousRecord,
  updateRecord,
  saveImageRecord,
  getblockFormular,
  savePorts,
  getblockPorts,
  getPortsByID,
  saveBatchRecord,
  getFormulaByID,
  updateIteration,
  imageFeedBack,
  getBlockByID,
  updateBlock,
  getTemplate,
  getOrganization,
  getHistory,
  getCommonUnit,
  getPRecordsaByProjects,
  deleteTask,
  getRecordAnalysis,
  getProcessPerBlock,
  getAllMachinesStats,
  getPDF,
};

export default apiCalls;
