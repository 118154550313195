import React, { useEffect, useState } from "react";
import { Form, Button, Stack, Alert, Row, Col } from "react-bootstrap";
import apiCalls from "../../Services/AxiosCalls";
import Select from "react-select";
import DataTable from "../../Components /DataTable";
import EditModal from "./EditModal";
import ExportCSV from "./ExportCSV";

const ShelfForm = () => {
  const [formData, setFormData] = useState({
    description: "",
    max_boxes: "",
    // number: "",
    name: "",
    rack: "", // Assuming rack ID 3 is passed
  });
  const [options, setOptions] = useState([]);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);
  const [showForm, setShowForm] = useState(true);
  const [shelves, setShelves] = useState([]); // State variable to toggle between form and DataTable
  const [openEdit, setOpenEdit] = useState(false);
  const [selectedRow, setSelectedRowId] = useState(null);
  const [downloadData, setDownloadData] = useState(null);

  const fetchData = async () => {
    try {
      const racks = await apiCalls.getRacks();
      const shelfData = await apiCalls.getShelves();
      setShelves(shelfData.data);
      //console.log(shelfData);
      const rackData = racks.data.map((data) => ({
        label: data.name,
        value: data.id,
      }));
      const items = {
        items: shelfData?.data.map((data) => ({
          title: `${data.name} of ${data.rack_name}`,
          data: JSON.stringify({
            key: "Shelf",
            id: data.id,
            name: data.name,
          }).replace(/"/g, '""'), // Properly escape quotes
          description: data.description,
        })),
      };
      setDownloadData(items);
      setOptions(rackData);
    } catch (error) {
      console.error("Error fetching storage facility data:", error);
      setError("Error fetching storage facility data");
    }
  };

  useEffect(() => {
    fetchData(); // Call the async function inside useEffect
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleSelectChange = (selectedOption) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      rack: selectedOption ? selectedOption.value : "",
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    setSuccess(false);
    //console.log("Form submitted:", formData);

    try {
      // Implement logic to submit the form data
      const response = await apiCalls.postShelf(formData);
      //console.log("Form submitted:", formData);
      setSuccess(true);
      await fetchData();
    } catch (error) {
      console.error("Error submitting form:", error);
      setError(error.response.data.error || error.messsage);
    }
  };

  const handleToggle = () => {
    setShowForm((prevShowForm) => !prevShowForm); // Toggle between showing form and DataTable
  };
  const headers = ["name", "max_boxes", "rack_name"];

  const handleRowClick = (rowData) => {
    //console.log(rowData);
    setOpenEdit(true);
    setSelectedRowId(rowData);
  };

  const toggleEditModal = () => {
    setOpenEdit((prevOpenEdit) => !prevOpenEdit);
    fetchData(); // Call the async function inside useEffect
  };
  

  return (
    <>
      <Row className="justify-content-between">
        <Col md={4}>
          <h5>Shelf</h5>
        </Col>
        <Col className="d-flex justify-content-end">
          <Button
            variant="link"
            onClick={handleToggle}
            className="d-flex justify-content-end"
          >
            {showForm ? "EDIT SHELF" : "CREATE SHELF"}{" "}
          </Button>
        </Col>
      </Row>
      {showForm ? (
        <>
          {error && <Alert variant="danger">{error}</Alert>}
          {success && (
            <Alert variant="success">Shelf saved successfully!</Alert>
          )}
          <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-3" controlId="formRack">
              <Form.Label>Rack</Form.Label>
              <Select
                options={options}
                onChange={handleSelectChange}
                placeholder="Select rack"
                value={options.find((option) => option.value === formData.rack)}
                isClearable
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formNumber">
              <Form.Label>Shelf Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter shelf name"
                name="name"
                value={formData.name}
                onChange={handleChange}
                required
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formMaxBoxes">
              <Form.Label>Maximum Boxes</Form.Label>
              <Form.Control
                type="number"
                placeholder="Enter maximum number of boxes"
                name="max_boxes"
                value={formData.max_boxes}
                onChange={handleChange}
                required
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formDescription">
              <Form.Label>Description</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                placeholder="Enter description"
                name="description"
                value={formData.description}
                onChange={handleChange}
              />
            </Form.Group>

            <Stack direction="horizontal" gap={3}>
              <Button variant="primary" type="submit">
                Submit
              </Button>
              {/* <Button variant="outline-danger">Cancel</Button> */}
            </Stack>
          </Form>
        </>
      ) : (
        <>
          {/* <Button
            variant="link"
            onClick={postQRCode}
            className="d-flex justify-content-end"
          >
            Get PDF
          </Button> */}
          <ExportCSV data={downloadData} />

          <DataTable
            headers={headers}
            data={shelves}
            onRowClick={handleRowClick}
          />
        </>
      )}
      {openEdit && (
        <EditModal
          show={openEdit}
          onHide={toggleEditModal}
          resourceType={"Shelves"}
          resourceData={selectedRow}
          title={`${selectedRow.name} of ${selectedRow.rack_name}`}
        />
      )}
    </>
  );
};

export default ShelfForm;
