import React, {
  useState,
  useRef,
  useEffect,
  forwardRef,
  useImperativeHandle,
  useCallback,
} from "react";
import DataTable from "react-data-table-component";
import {
  Modal,
  Button,
  Offcanvas,
  Image,
  ProgressBar,
  Spinner,
} from "react-bootstrap";
import ImageStats from "./ImageStats";
import { exportImagesToZip } from "./exportImagesToZip";
import { saveAs } from "file-saver"; // For file download
import Compress from "compress.js"; // Ensure this import is available
import imageCompression from "browser-image-compression";

const InteractiveTable = forwardRef(({ data, filterData, exportData }, ref) => {
  const linkRef = useRef();
  const [showModal, setShowModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const [showFilter, setShowFilter] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [filterText, setFilterText] = useState("");
  const [filteredData, setFilteredData] = useState(data);
  const [progress, setProgress] = useState(0);
  const [isGenerating, setIsGenerating] = useState(false);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [currentBatch, setCurrentBatch] = useState(0);
  const [totalBatches, setTotalBatches] = useState(0);

  useEffect(() => {
    const handleFilter = () => {
      const text = filterData.toLowerCase();
      setFilterText(text);

      const filteredItems = data.filter((item) => {
        return columns.some((col) => {
          const value = col.selector ? col.selector(item) : "";
          return String(value).toLowerCase().includes(text);
        });
      });

      setFilteredData(filteredItems);
    };
    handleFilter();
  }, [filterData, data]);

  const handleWorkerMessage = (e) => {
    console.log(e);

    if (e.data.type === "progress") {
      setLoading(true);
      setProgress(e.data.value);
    } else if (e.data.type === "complete") {
      saveAs(e.data.blob, "seed_analysis_report.pdf");
      // setIsGenerating(false);
      setLoading(false);
      setProgress(0);
    } else if (e.data.type === "error") {
      setError(e.data.error);
      setIsGenerating(false);
      setLoading(false);

      setProgress(0);
    }
  };

  // Helper function to compress images
  const compressImage = async (imageSrc) => {
    try {
      // Load the image with CORS enabled
      const image = await new Promise((resolve, reject) => {
        const img = document.createElement("img"); // Use document.createElement to create a native image element
        img.crossOrigin = "Anonymous"; // Allow cross-origin images
        img.src = imageSrc;
        img.onload = () => resolve(img);
        img.onerror = reject;
      });

      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");
      canvas.width = image.width;
      canvas.height = image.height;
      ctx.drawImage(image, 0, 0, canvas.width, canvas.height);

      const blob = await new Promise((resolve) => canvas.toBlob(resolve));

      // Compress the image
      const compressedBlob = await imageCompression(blob, {
        maxSizeMB: 0.1, // Max size in MB
        maxWidthOrHeight: 800, // Max dimensions
        useWebWorker: true, // Use a web worker for compression
      });

      // Convert compressed blob to object URL
      return URL.createObjectURL(compressedBlob);
    } catch (error) {
      console.error("Error compressing image:", error);
      return imageSrc; // Fallback to original image if compression fails
    }
  };

  const generatePDF = useCallback(async () => {
    setIsGenerating(true);
    setError(null);
    console.log(selectedRows);
  
    const worker = new Worker(new URL("./pdfWorker.js", import.meta.url));
    worker.onmessage = handleWorkerMessage;
  
    const dataToExport = selectedRows?.length > 0 ? selectedRows : filteredData;
    const batchSize = 40;
    const batches = Math.ceil(dataToExport.length / batchSize);
    setTotalBatches(batches); // Set total batches
  
    // Process each batch
    for (let i = 0; i < batches; i++) {
      setCurrentBatch(i + 1); // Update the current batch being processed
  
      const startIndex = i * batchSize;
      const endIndex = Math.min(startIndex + batchSize, dataToExport.length);
  
      const batchData = await Promise.all(
        dataToExport.slice(startIndex, endIndex).map(async (item) => {
          // Destructure stats and exclude "k10" and "s15"
          const { stats, ...restImageData } = item.image_data;
          const { k10, s15, ...filteredStats } = stats; // Exclude k10 and s15 from stats
  
          return {
            ...item,
            image_data: {
              ...restImageData,
              stats: filteredStats, // Use the filtered stats
              image_in: await compressImage(item.image_data.image_in),
              image_out: await compressImage(item.image_data.image_out),
            },
          };
        })
      );
  
      worker.postMessage({ dataArray: batchData, startIndex, endIndex });
    }
  
    setIsGenerating(false); // Set to false once all batches are processed
  }, [selectedRows, filteredData]);
  

  useImperativeHandle(ref, () => ({
    downloadImages: async () => {
      const dataToExport =
        selectedRows?.length > 0 ? selectedRows : filteredData;
      await exportImagesToZip(dataToExport);
    },
    downloadCSV: () => {
      handleExportCSV();
    },
    generatePDF: () => {
      generatePDF();
    },
  }));

  const handleRowSelected = ({ selectedRows }) => {
    setSelectedRows(selectedRows);
  };

  const handleExportCSV = () => {
    // Function to escape CSV values
    const escapeCsvValue = (value) => {
      // Convert the value to a string
      const str = String(value);

      // If the value contains a comma, newline, or double-quote, it should be enclosed in double quotes
      if (str.includes(",") || str.includes("\n") || str.includes('"')) {
        // Double-quote any double quotes in the value and enclose the whole value in double quotes
        return `"${str.replace(/"/g, '""')}"`;
      }
      return str;
    };

    // Get the headers from columns
    const headers = columns.map((col) => escapeCsvValue(col.name)).join(",");

    // Get the data to export
    const exportData = selectedRows?.length > 0 ? selectedRows : filteredData;

    // Create the CSV rows
    const rows = exportData.map((row) =>
      columns
        .map((col) => escapeCsvValue(col.selector ? col.selector(row) : ""))
        .join(",")
    );

    // Join headers and rows into CSV content
    const csvContent = [headers, ...rows].join("\n");

    // Create a Blob and URL for the CSV content
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);

    // Create a link and trigger the download
    const a = document.createElement("a");
    a.href = url;
    a.download = "data.csv";
    document.body.appendChild(a); // Append link to the DOM for Firefox
    a.click();
    document.body.removeChild(a); // Remove the link after downloading

    // Clean up URL object
    URL.revokeObjectURL(url);
  };

  const columns = [
    {
      name: "Weight-In",
      selector: (row) => row.image_data.weight,
      sortable: true,
    },
    // {
    //   name: "k10",
    //   selector: (row) => row.image_data?.all_data_out?.stats?.k10 ?? "N/A",
    //   sortable: true,
    // },
    // {
    //   name: "s15",
    //   selector: (row) => row.image_data?.all_data_out?.stats?.s15 ?? "N/A",
    //   sortable: true,
    // },
    {
      name: "tkw",
      selector: (row) => row.image_data?.all_data_out?.stats?.tkw ?? "N/A",
      sortable: true,
    },
    {
      name: "count",
      selector: (row) => row.image_data?.all_data_out?.stats?.count ?? "N/A",
      sortable: true,
    },
    {
      name: "standard width",
      selector: (row) =>
        row.image_data?.all_data_out?.stats?.std_width ?? "N/A",
      sortable: true,
    },
    {
      name: "average length",
      selector: (row) =>
        row.image_data?.all_data_out?.stats?.avg_length ?? "N/A",
      sortable: true,
    },
    {
      name: "average surface",
      selector: (row) =>
        row.image_data?.all_data_out?.stats?.avg_surface ?? "N/A",
      sortable: true,
    },
    {
      name: "average width",
      selector: (row) =>
        row.image_data?.all_data_out?.stats?.avg_width ?? "N/A",
      sortable: true,
    },
    {
      name: "Images",
      cell: (row) => (
        <div>
          <a href={row.image_data.image_out}>
            <img
              // onClick={() => setShowModal(false)}
              src={row.image_data.image_out}
              width="50"
              alt="seed Out"
            />
          </a>
        </div>
      ),
      sortable: false,
    },
  ];

  const firstDataObj = data[0];
  if (firstDataObj) {
    const dataDictKeys = Object.keys(firstDataObj.data_dict);
    const hasCSI = dataDictKeys.includes("CSI");

    if (hasCSI) {
      columns.unshift(
        { name: "CSI", selector: (row) => row.data_dict.CSI, sortable: true },
        { name: "Farm", selector: (row) => row.data_dict.Farm, sortable: true },
        {
          name: "Trial",
          selector: (row) => row.data_dict.Trial,
          sortable: true,
        },
        { name: "Year", selector: (row) => row.data_dict.Year, sortable: true },
        { name: "Plot", selector: (row) => row.data_dict.Plot, sortable: true },
        {
          name: "Lot Number/Line_ID",
          selector: (row) =>
            row.data_dict.Lot_Number
              ? row.data_dict.Lot_Number
              : row.data_dict.Line_ID,

          sortable: true,
        }
      );
    } else {
      columns.unshift({
        name: "Item",
        selector: (row) => row.item,
        sortable: true,
      });
    }
  }

  const ExpandedComponent = ({ data }) => {
    const records = data.image_data?.all_data_out.records || [];
    return <DataTable columns={columnsRecords} data={records} dense />;
  };

  const columnsRecords = [
    {
      name: "Color",
      selector: (row) => row.color,
      cell: (row) => (
        <div
          style={{
            backgroundColor: row.color,
            borderRadius: "50%",
            width: "20px",
            height: "20px",
          }}
        ></div>
      ),
      sortable: true,
    },
    { name: "Area", selector: (row) => row.area, sortable: true },
    { name: "Width", selector: (row) => row.width, sortable: true },
    { name: "Length", selector: (row) => row.length, sortable: true },
  ];

  return (
    <div className="container-fluid">
      <DataTable
        columns={columns}
        data={filteredData}
        pagination
        selectableRows
        onSelectedRowsChange={handleRowSelected}
        expandableRows
        expandableRowsComponent={ExpandedComponent}
        highlightOnHover
        responsive
        theme="solarized"
        striped
      />
      {selectedRow && (
        <Offcanvas
          show={showFilter}
          onHide={() => setShowFilter(false)}
          placement="end"
        >
          <Offcanvas.Header closeButton />
          <Offcanvas.Body style={{ overflowY: "auto", maxHeight: "100vh" }}>
            <ImageStats
              image_in={selectedRow.image_data.image_in}
              image_out={selectedRow.image_data.image_out}
              stats={selectedRow.image_data.stats}
              records={selectedRow.image_data.all_data_out}
            />
          </Offcanvas.Body>
        </Offcanvas>
      )}
      <a ref={linkRef} style={{ display: "none" }} />
      <Modal show={showModal} onHide={() => setShowModal(false)} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Image Preview</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedImage && <Image src={selectedImage} fluid />}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      {loading && (
        <div className="file-download-container">
          <p className="file-download-text">📥 Downloading File</p>
          <div className="progress-bar-container">
            <ProgressBar
              animated
              striped
              variant="info"
              now={progress}
              label={`${progress}%`}
              className="progress-bar-custom"
            />
          </div>
        </div>
      )}

      {isGenerating && (
        <div className="file-generation-container">
          <p className="file-generation-text">
            Preparing your file, please wait...
            <span className="spinner-container">
              <Spinner animation="border" variant="primary" size="sm" />
            </span>
          </p>
          <div className="batch-progress">
            <ProgressBar
              animated
              striped
              variant="success"
              now={(currentBatch / totalBatches) * 100}
              label={`Batch ${currentBatch} of ${totalBatches}`}
            />
          </div>
          <p className="batch-info">
            Processing batch {currentBatch} of {totalBatches}
          </p>
        </div>
      )}

      {error && <p>Error: {error}</p>}
    </div>
  );
});

export default InteractiveTable;
