import React, { useEffect, useState } from "react";
import apiCalls from "../../Services/AxiosCalls";
import styled from "styled-components";
import { Home as HomeIcon, Business as BusinessIcon } from "@mui/icons-material";
import { Grid } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowProgress,
  faBlock,
  faFunction,
  faHardDrive,
  faInventory,
  faUser,
  faBook
} from "@fortawesome/pro-solid-svg-icons";
import { Link } from "react-router-dom"; // Import Link from react-router-dom
import CustomCard from "../../Components /CustomCard"; // Make sure the import path is correct
import SettingsCard from "./SettingsCard";

const Settings = () => {
  const [stations, setStations] = useState(null);

  useEffect(() => {
    // getUsers();
  }, []);

  // const getUsers = async () => {
  //   const users = await apiCalls.getAllStations();
  //   setStations(users?.data);
  // };

  const icon1 = <FontAwesomeIcon icon={faHardDrive} className="mr-2" />;
  const images = <FontAwesomeIcon icon={faBlock} className="mr-2" />;
  const projectsIcon = <FontAwesomeIcon icon={faFunction} className="mr-2" />;
  const protocol = <FontAwesomeIcon icon={faArrowProgress} className="mr-2" />;
  const inventory = <FontAwesomeIcon icon={faInventory} className="mr-2" />;
  const usersIcon = <FontAwesomeIcon icon={faUser} className="mr-2" />; // Renamed to avoid conflict with users state
  const sop = <FontAwesomeIcon icon={faBook} className="mr-2"  />

  const cardsData = [
    { name: "Stations", icon: icon1, to: "/settings/stations", color: '#1cc88a', count:"Work Stations"},
    { name: "Blocks", icon: images, to: "/settings/blocks", color: '#e74a3b', count:'Equipments in work stations' },
    { name: "Formulas", icon: projectsIcon, to: "/settings/formulas", color: '#36b9cc', count:'Formulas for calculations'},
    { name: "Protocols", icon: protocol, to: "/settings/protocols", color: '#4e73df', count:'Collection of blocks' },
    { name: "Inventory", icon: inventory, to: "/settings/inventory", color: '#f6c23e' , count:'Create inventory architecture' },
    { name: "Users", icon: usersIcon, to: "/settings/users", color: '#ba4edf', count:'Users in current department' },
    { name: "SOPs", icon: sop, to: "/settings/docs", color: 'gray', count:'Read documentations' },

  ];

  return (
    <>
      <Grid container spacing={2} className="card-row">
        {cardsData.map((card, index) => (
          <Grid item key={index}>
            <Link to={card.to} style={{ textDecoration: 'none' }}>
              <SettingsCard name={card.name} icon={<span>{card.icon}</span>} color={card.color} count={card.count} />
            </Link>
          </Grid>
        ))}
      </Grid>
    </>
  );
};

export default Settings;
